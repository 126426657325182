export class Color {
    public r: number;
    public g: number;
    public b: number;
    public a: string;
    public h: number;
    public s: number;
    public l: number;

    constructor(r: number, g: number, b: number, a: string) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    public static fromHex(hex: string): Color {
        var r, g, b, a;
        if (hex.length == 7) {
            r = "0x" + hex[1] + hex[2];
            g = "0x" + hex[3] + hex[4];
            b = "0x" + hex[5] + hex[6];
            a = "0x" + 'f' + 'f';

        } else if (hex.length == 9) {
            r = "0x" + hex[1] + hex[2];
            g = "0x" + hex[3] + hex[4];
            b = "0x" + hex[5] + hex[6];
            a = "0x" + hex[7] + hex[8];
        }
        a = +(a / 255).toFixed(2);
        return new Color(+r, +g, +b, a);
    }

    public toRgbaHex(color: Color): string {
        var r, g, b, a;
        r = color.r.toString(16);
        g = color.g.toString(16);
        b = color.b.toString(16);
        a = Math.round(+color.a * 255).toString(16);

        if (r.length == 1)
            r = "0" + r;
        if (g.length == 1)
            g = "0" + g;
        if (b.length == 1)
            b = "0" + b;
        if (a.length == 1)
            a = "0" + a;

        return "#" + r + g + b + a;
    }

    public toRgbHex(color: Color): string {
        var r, g, b;

        r = color.r.toString(16);
        g = color.g.toString(16);
        b = color.b.toString(16);

        if (r.length == 1)
            r = "0" + r;
        if (g.length == 1)
            g = "0" + g;
        if (b.length == 1)
            b = "0" + b;
        return "#" + r + g + b;
    }

    // public toRgbaString(): string {
    //     return `rgba(${this.r},${this.g},${this.b},${this.a})`;
    // }

    // public toRgbString(): string {
    //     return `rgb(${this.r},${this.g},${this.b})`;
    // }

    // public static fromRgbaString(colorStr: string): Color {
    //     let rgba = colorStr.split('(')[1].split(')')[0].split(',');
    //     let alpha = rgba.length > 3 ? rgba[3] : '1';
    //     return new Color(+rgba[0], +rgba[1], +rgba[2], alpha);
    // }

    public toHsl() {
        let red = this.r / 255;
        let green = this.g / 255;
        let blue = this.b / 255;
        let cmin = Math.min(red, green, blue);
        let cmax = Math.max(red, green, blue);
        let delta = cmax - cmin;
        if (delta == 0) {
            this.h = 0;
        } else if (cmax == red) {
            this.h = ((green - blue) / delta) % 6;
        } else if (cmax == green) {
            this.h = (blue - red) / delta + 2;
        } else {
            this.h = (red - green) / delta + 4;
        }
        this.h = Math.round(this.h * 60);
        if (this.h < 0) {
            this.h += 360;
        }
        this.l = (cmax + cmin) / 2;
        this.s = (delta == 0) ? 0 : delta / (1 - Math.abs(2 * this.l - 1));

        this.l = +(this.l * 100).toFixed(1);
        this.s = +(this.l * 100).toFixed(1);
    }

    public equal(color: Color): boolean {
        return this.r == color.r && this.g == color.g && this.b == color.b && +this.a == +color.a;
    }
}