/**
 * class DisplaySettingSchedule
 */
export class DisplaySettingSchedule {
  /**
   * id
   */
  id: Number;
  /**
   * name
   */
  name: string;
  /**
   * templateIds
   */
  templateIds: Array<Number>;
  /**
   * userId
   */
  userId: string;
  /**
   * screen name
   */
  screenName: string;
  constructor() {}
}

export class DisplaySetting {
  /**
   * id
   */
  id: number;
  /**
   * templateIds
   */
  templateId: Number;

  constructor(id?: number, templateId?: Number) {
    this.id = id;
    this.templateId = templateId;
  }
}
