import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Helper } from 'app/common/helper';
import { DateTime } from 'luxon';
import { CustomDatePickerI18n } from './custom-date-picker-i18n';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatePickerI18n }]
})
/**
 * Custom date time picker component based on ng-bootstrap date picker & time picker
 */
export class CustomDatePickerComponent {
  // set to true when time is included
  @Input() public withTime: boolean;

  // set to true when in daily schedule pop up
  @Input() public width: String;

  // set to true when in dialog pop up
  @Input() public inDialog: boolean;

  // input date time string
  @Input() public currentDateTime: Date;

  /**
   * is valid
   */
  @Input() public isValid: boolean;

  /**
   * left
   */
  @Input() public left: String;

  /**
   * margin left
   */
  @Input() public marginLeft: String;

  /**
   * has border
   */
  @Input() public hasBorder: boolean;

  /**
   * is scroll top
   */
  @Input() public isScrollTop: boolean = false;

  /**
   * date display
   */
  public dateDisplay: string;

  // current date struct
  public currentDateStruct: NgbDateStruct;

  // current time struct
  public currentTimeStruct: NgbTimeStruct;

  // event emitted when date or time is changed
  @Output() public currentDateChange = new EventEmitter<String>();

  private static readonly DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

  private static readonly DATE_FORMAT = 'yyyy-MM-dd';
  currentDate = new Date();

  date;

  time;

  constructor() {}

  ngOnInit() {
    if (this.withTime) {
      // this.currentDate = new Date();
      // this.time = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };
      // this.currentDateTime = moment(this.currentDate).format('MMM. DD, YYYY');
    } else {
      if (this.currentDateTime) {
        // let stringDate = (this.currentDateTime as string).split('-');
        this.currentDate = this.currentDateTime;
      }
      this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
      this.dateDisplay = this.currentDateTime ? DateTime.fromObject(this.date).toFormat('yyyy.MM.dd') : '';
    }
    this.currentDateStruct = this.date;
  }

  /**
   * choose a date value
   * @param selectedDate chosen date struct
   */
  chooseDate(selectedDate) {
    this.currentDateStruct = selectedDate;
    this.date = this.currentDateStruct;
    if (this.withTime) {
      // let datetime = DateTime.fromObject({
      //   year: this.currentDateStruct.year,
      //   month: this.currentDateStruct.month,
      //   day: this.currentDateStruct.day
      // });
      // this.currentDateTime = moment(this.currentDateTime as string).format('MMM. DD, YYYY');
      // this.currentDateChange.emit(datetime.toFormat(CustomDatePickerComponent.DATE_TIME_FORMAT));
      // return;
    }
    // this.currentDateTime = DateTime(this.currentDateTime as string).format('MMM. DD, YYYY');
    let dateTime = DateTime.fromObject(this.currentDateStruct);
    this.dateDisplay = dateTime.toFormat('yyyy.MM.dd');
    this.currentDateChange.emit(dateTime.toFormat(CustomDatePickerComponent.DATE_FORMAT));
  }

  /**
   * choose a time value
   * @param selectedTime chosen time struct
   */
  chooseTime(selectedTime: NgbTimeStruct) {
    if (!this.withTime) {
      return;
    }
    this.currentTimeStruct = selectedTime;
    let dateTime = DateTime.fromObject({
      year: this.currentDateStruct.year,
      month: this.currentDateStruct.month,
      day: this.currentDateStruct.day,
      hour: this.currentTimeStruct.hour,
      minute: this.currentTimeStruct.minute
    });
    this.time = { hour: this.currentTimeStruct.hour, minute: this.currentTimeStruct.minute };
    this.currentDateChange.emit(dateTime.toFormat(CustomDatePickerComponent.DATE_TIME_FORMAT));
  }
}
