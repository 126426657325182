import { TimetableDetail } from './timetable-detail';
import { DisplayTemplate } from './display-template';
import { Label } from './label';
import { Template } from './template';
import { TimetableSchedule } from './timetable-schedule';

export class Timetable {
  /**
   * timetable id
   */
  id: Number;
  /**
   * Label
   */
  label: Label;
  /**
   * timetable name
   */
  name: string;
  /**
   * timetable no
   */
  no: string;
  /**
   * suffix
   */
  suffix: string;
  /**
   * display1 templates
   */
  templateDisplay1s: Array<Template>;
  /**
   * display2 templates
   */
  templateDisplay2s: Array<Template>;
  /**
   * displayTemplate1
   */
  displayTemplate1: DisplayTemplate;
  /**
   * displayTemplate2
   */
  displayTemplate2: DisplayTemplate;
  /**
   * true if timetable is checked
   */
  isChecked: boolean;
  /**
   * true if timetable is edited
   */
  isEdit: boolean;
  /**
   * list timetable details
   */
  timetableSchedule: TimetableSchedule;
  /**
   * Timetable Detail
   */
  timetableDetails: Array<TimetableDetail>;

  constructor(id?: Number, name?: string, no?: string, suffix?: string) {
    this.id = id;
    this.name = name;
    this.no = no;
    this.suffix = suffix;
  }
}
