import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogSettingProgramComponent } from 'app/dialog/dialog-setting-program/dialog-setting-program.component';
import { Common } from 'app/model/entity/common';
import { Media } from 'app/model/entity/media';
import { SaveDigitalSignageEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { ContentDayService } from 'app/service/content-day.service';
import { DailyScheduleProgramService } from 'app/service/daily-schedule-program.service';
import { MediaService } from 'app/service/media.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Helper } from '../../common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, RepeatModeEnum, TimeDivision, TypeMediaFileEnum } from '../../config/constants';
import { DialogChannelComponent } from '../../dialog/dialog-channel/dialog-channel.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogPlaylistRecurrenceComponent } from '../../dialog/dialog-playlist-recurrence/dialog-playlist-recurrence.component';
import { DialogPlaylistComponent } from '../../dialog/dialog-playlist/dialog-playlist.component';
import { DialogPublishDataSignageChannelComponent } from '../../dialog/dialog-publish-data-signage-channel/dialog-publish-data-signage-channel.component';
import { Channel } from '../../model/entity/channel';
import { ContentDay } from '../../model/entity/content-day';
import { DailySchedule, Program } from '../../model/entity/playlist';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { MenuActionService } from '../../service/menu-action.service';
import { SignageChannelService } from '../../service/signage-channel.service';
import { SignageDailyScheduleService } from '../../service/signage-playlist.service';

@Component({
  selector: 'app-digital-signage-content-editor',
  templateUrl: './digital-signage-content-editor.component.html',
  styleUrls: ['./digital-signage-content-editor.component.scss']
})
/**
 * Component class for Digital Signage Content Editor
 */
export class DigitalSignageContentEditorComponent implements OnInit, OnDestroy {
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('mediaplayer', { static: false }) mediaplayer: ElementRef;
  /**
   * timeline division changing step
   */
  readonly TIMELINE_STEP: number = 0;
  /**
   * timeline init
   */
  readonly TIMELINE_INIT: number = 120;
  /**
   * time division level
   */
  TimeDivision: TimeDivision;
  /**
   * true if channel tab is showed and vice versa
   */
  isShowChannel: boolean;
  /**
   * channel list
   */
  channels: Array<Channel> = new Array<Channel>();
  /**
   * playlist list
   */
  dailySchedules: Array<DailySchedule> = new Array<DailySchedule>();
  /**
   * selected channel
   */
  selectedChannel: Channel;
  /**
   * selected playlist
   */
  dailyScheduleSelected: DailySchedule;
  /**
   * selected day
   */
  selectedDay: ContentDay;
  /**
   * timestamp list
   */
  channelTimestamps: Array<Timestamp>;
  /**
   * timeline division
   */
  timelineDivision: number = this.TIMELINE_STEP;
  /**
   * playlist colors
   */
  dailyScheduleColors: Array<{ colorCode: string; colorSelected: string; value: any }> = Array(
    { colorCode: '#ffb8b8', colorSelected: '#e89999', value: null },
    { colorCode: '#ffd18a', colorSelected: '#deb371', value: null },
    { colorCode: '#ffeb8d', colorSelected: '#ffeb16', value: null },
    { colorCode: '#ffff8c', colorSelected: '#f1f17b', value: null },
    { colorCode: '#d5ff96', colorSelected: '#bfe881', value: null },
    { colorCode: '#b6f9ba', colorSelected: '#8ad88c', value: null },
    { colorCode: '#b8e9ff', colorSelected: '#b8e9f0', value: null },
    { colorCode: '#bad0ff', colorSelected: '#a0b7e8', value: null },
    { colorCode: '#d5baff', colorSelected: '#bf93c5', value: null },
    { colorCode: '#c7c7c7', colorSelected: '#adabab', value: null }
  );
  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: 'January', key: 0 },
    { value: 'February', key: 1 },
    { value: 'March', key: 2 },
    { value: 'April', key: 3 },
    { value: 'May', key: 4 },
    { value: 'June', key: 5 },
    { value: 'July', key: 6 },
    { value: 'August', key: 7 },
    { value: 'September', key: 8 },
    { value: 'October', key: 9 },
    { value: 'November', key: 10 },
    { value: 'December', key: 11 }
  );
  /**
   * list of subscriptions for action
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * true when press mouse button on signage program
   */
  isMouseDownProgram: boolean;
  /**
   * last value of mouse y-position
   */
  startPosY: number;
  /**
   * selected program in playlist
   */
  selectedProgram: Program;
  /**
   * last value of duration pixel
   */
  oldDurationPixel: number;
  /**
   * last value of start pixel
   */
  oldStartPixel: number;
  /**
   * true when changing duration of video
   */
  isChangeDuration: boolean;
  /**
   * true when changing start time pf video
   */
  isChangeStart: boolean;
  /**
   * current progress of video playing
   */
  currentProgress: number = 0;
  /**
   * current index of playing program
   */
  currentProgramIndex: number = -1;
  /**
   * list interval
   */
  intervalList: Array<any> = new Array<any>();
  /**
   * true if video on
   */
  isOnVideo = false;
  /**
   * object HTMLVideoElement
   */
  previewVideo: HTMLVideoElement;
  /**
   * month selected
   */
  selectedMonth: any;
  /**
   * year selected
   */
  selectedYear: number;
  /**
   * index
   */
  index: number;
  /**
   * calendar day
   */
  calendarDay: ContentDay;
  /**
   * true if > finish month
   */
  isNextMonth: boolean = false;
  /**
   * true if < start month
   */
  isPreviousMonth: boolean = true;
  /**
   * true if click pause video
   */
  isPauseVideo: boolean = false;
  /**
   * true if click play video
   */
  isPlayVideo: boolean = false;
  /**
   * true if next or previous video on
   */
  isNextVideoOn: boolean = false;
  /**
   * time unit
   */
  readonly UNIT_TIME: number = 60;
  /**
   * chosen program
   */
  chosenProgram: Program;
  /**
   * channel name send from tab digital signage content
   */
  channelName = Constant.EMPTY;
  /**
   * channel data send from tab digital signage content
   */
  channelData: Channel;

  projectId: Number;

  readonly Constant = Constant;
  /**
   * time start of video
   */
  timeStart: number = 0;
  /**
   * true if change start time or duration program
   */
  isChangeProgram: boolean = false;
  /**
   * old start time of program (handle error time dblclick)
   */
  oldStartTime: number = 0;
  /**
   * true if publish data
   */
  isPublish: boolean = false;

  isChangedData: boolean = false;

  isChangeDailySchedule: boolean = false;

  isSettingProgram: boolean = false;

  isChangeChannel: boolean = false;

  contentDaysMonth: Array<ContentDay>;
  /**
   * list media of sequence
   */
  mediaListOfSequence: Array<Media>;

  stateOfComponent: {
    isChangeLayout: boolean;
    isShowChannel: boolean;
    channels: Channel[];
    dailySchedules: DailySchedule[];
    selectedChannel: Channel;
    dailyScheduleSelected: DailySchedule;
    selectedDay: ContentDay;
    channelTimestamps: Timestamp[];
    timelineDivision: number;
    selectedProgram: Program;
    isChangeDuration: boolean;
    isChangeStart: boolean;
    currentProgress: number;
    currentProgramIndex: number;
    isOnVideo: boolean;
    previewVideo: HTMLVideoElement;
    selectedMonth: any;
    selectedYear: number;
    index: number;
    calendarDay: ContentDay;
    isNextMonth: boolean;
    isPreviousMonth: boolean;
    chosenProgram: Program;
    channelData: Channel;
    timeStart: number;
    isChangeProgram: boolean;
    isChangeDailySchedule: boolean;
    isChangeChannel: boolean;
    contentDaysMonth: ContentDay[];
    isChangedData: boolean;
    mediaListOfSequence: Array<Media>;
  };

  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private channelService: SignageChannelService,
    private dailyScheduleService: SignageDailyScheduleService,
    private dailyScheduleProgramService: DailyScheduleProgramService,
    private contentDayService: ContentDayService,
    private dialogService: DialogService,
    private menuActionMenu: MenuActionService,
    private renderer: Renderer2,
    private toast: ToastrService,
    private dataService: DataService,
    private mediaService: MediaService,
    public readonly store: Store<AppState>,
    private commonService: CommonService
  ) {
    this.subscriptions.push(
      this.menuActionMenu.actionAddNewChannel.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (this.isShowChannel) {
            this.addChannel();
          } else {
            return;
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionMenu.actionAddNewDaily.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (!this.isShowChannel) {
            this.addPlaylist();
          } else {
            return;
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionMenu.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (this.isShowChannel) {
            this.editChannel(this.selectedChannel, true);
          } else {
            this.editDailySchedule(this.dailyScheduleSelected);
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionMenu.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (this.isShowChannel) {
            if (this.selectedDay) {
              this.deleteContentDay();
            } else {
              this.deleteChannel();
            }
          } else {
            if (this.chosenProgram) {
              this.deleteProgram();
            } else {
              this.deleteDailySchedule();
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionMenu.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (this.isShowChannel) {
            this.saveChannelsWithContentDays();
          } else {
            this.saveDailySchedulesWithPrograms();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionMenu.actionPublishData.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
          if (this.isShowChannel) {
            this.publishData();
          }
        }
      })
    );
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.DIGITAL_SIGNAGE_NAME) {
        this.channelName = data[0];
        this.channelData = data[1];
      }
    });
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.digitalSignageEditorState?.stateOfComponent.isChangeLayout,
            isShowChannel: componentState.digitalSignageEditorState?.stateOfComponent.isShowChannel,
            channels: componentState.digitalSignageEditorState?.stateOfComponent.channels,
            dailySchedules: componentState.digitalSignageEditorState?.stateOfComponent.dailySchedules,
            selectedChannel: componentState.digitalSignageEditorState?.stateOfComponent.selectedChannel,
            dailyScheduleSelected: componentState.digitalSignageEditorState?.stateOfComponent.dailyScheduleSelected,
            selectedDay: componentState.digitalSignageEditorState?.stateOfComponent.selectedDay,
            channelTimestamps: componentState.digitalSignageEditorState?.stateOfComponent.channelTimestamps,
            timelineDivision: componentState.digitalSignageEditorState?.stateOfComponent.timelineDivision,
            selectedProgram: componentState.digitalSignageEditorState?.stateOfComponent.selectedProgram,
            isChangeDuration: componentState.digitalSignageEditorState?.stateOfComponent.isChangeDuration,
            isChangeStart: componentState.digitalSignageEditorState?.stateOfComponent.isChangeStart,
            currentProgress: componentState.digitalSignageEditorState?.stateOfComponent.currentProgress,
            currentProgramIndex: componentState.digitalSignageEditorState?.stateOfComponent.currentProgramIndex,
            isOnVideo: componentState.digitalSignageEditorState?.stateOfComponent.isOnVideo,
            previewVideo: componentState.digitalSignageEditorState?.stateOfComponent.previewVideo,
            selectedMonth: componentState.digitalSignageEditorState?.stateOfComponent.selectedMonth,
            selectedYear: componentState.digitalSignageEditorState?.stateOfComponent.selectedYear,
            index: componentState.digitalSignageEditorState?.stateOfComponent.index,
            calendarDay: componentState.digitalSignageEditorState?.stateOfComponent.calendarDay,
            isNextMonth: componentState.digitalSignageEditorState?.stateOfComponent.isNextMonth,
            isPreviousMonth: componentState.digitalSignageEditorState?.stateOfComponent.isPreviousMonth,
            chosenProgram: componentState.digitalSignageEditorState?.stateOfComponent.chosenProgram,
            channelData: componentState.digitalSignageEditorState?.stateOfComponent.channelData,
            timeStart: componentState.digitalSignageEditorState?.stateOfComponent.timeStart,
            isChangeProgram: componentState.digitalSignageEditorState?.stateOfComponent.isChangeProgram,
            isChangeDailySchedule: componentState.digitalSignageEditorState?.stateOfComponent.isChangeDailySchedule,
            isChangeChannel: componentState.digitalSignageEditorState?.stateOfComponent.isChangeChannel,
            contentDaysMonth: componentState.digitalSignageEditorState?.stateOfComponent.contentDaysMonth,
            isChangedData: componentState.digitalSignageEditorState?.stateOfComponent.isChangedData,
            mediaListOfSequence: componentState.digitalSignageEditorState?.stateOfComponent.mediaListOfSequence
          };
        })
    );
    this.commonObject = commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.projectId = this.commonObject?.projectId;
    if (!this.stateOfComponent?.isChangeLayout) {
      this.isShowChannel = !!this.commonObject?.isShowChannel;
      this.dataService.sendData(['publish', this.isShowChannel]);
      this.fetchPlaylists();
      if (this.channelName == Constant.DIGITAL_SIGNAGE_NAME) {
        this.isShowChannel = true;
        this.commonObject.isShowChannel = this.isShowChannel;
        Helper.saveMainStateAction(this.store, this.commonObject);
        this.getChannels(this.channelName);
      } else {
        this.getChannels();
      }
      // calculator startPixel and durationPixel of program
      this.updateTimePrograms(120);
    } else {
      this.handleAfterChangeLayout();
    }
    this.previewVideo = document.getElementById('previewVideo') as HTMLVideoElement;
    this.previewVideo.src = undefined;
    this.previewVideo.style.background = '#000';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stopVideo();
    this.store.dispatch(
      new SaveDigitalSignageEditorStateAction({
        isChangeLayout: true,
        isShowChannel: this.isShowChannel,
        channels: this.channels,
        dailySchedules: this.dailySchedules,
        selectedChannel: this.selectedChannel,
        dailyScheduleSelected: this.dailyScheduleSelected,
        selectedDay: this.selectedDay,
        channelTimestamps: this.channelTimestamps,
        timelineDivision: this.timelineDivision,
        selectedProgram: this.selectedProgram,
        isChangeDuration: this.isChangeDuration,
        isChangeStart: this.isChangeStart,
        currentProgress: this.currentProgress,
        currentProgramIndex: this.currentProgramIndex,
        isOnVideo: this.isOnVideo,
        previewVideo: this.previewVideo,
        selectedMonth: this.selectedMonth,
        selectedYear: this.selectedYear,
        index: this.index,
        calendarDay: this.calendarDay,
        isNextMonth: this.isNextMonth,
        isPreviousMonth: this.isPreviousMonth,
        chosenProgram: this.chosenProgram,
        channelData: this.channelData,
        timeStart: this.timeStart,
        isChangeProgram: this.isChangeProgram,
        isChangeDailySchedule: this.isChangeDailySchedule,
        isChangeChannel: this.isChangeChannel,
        contentDaysMonth: this.contentDaysMonth,
        isChangedData: this.isChangedData,
        mediaListOfSequence: this.mediaListOfSequence
      })
    );
    delete this.commonObject.isShowChannel;
  }

  private handleAfterChangeLayout() {
    this.isShowChannel = this.stateOfComponent.isShowChannel;
    this.channels = this.stateOfComponent.channels;
    this.dailySchedules = this.stateOfComponent.dailySchedules;
    this.selectedChannel = this.stateOfComponent.selectedChannel;
    this.dailyScheduleSelected = this.stateOfComponent.dailyScheduleSelected;
    this.selectedDay = this.stateOfComponent.selectedDay;
    this.channelTimestamps = this.stateOfComponent.channelTimestamps;
    this.timelineDivision = this.stateOfComponent.timelineDivision;
    this.selectedProgram = this.stateOfComponent.selectedProgram;
    this.isChangeDuration = this.stateOfComponent.isChangeDuration;
    this.isChangeStart = this.stateOfComponent.isChangeStart;
    this.currentProgress = this.stateOfComponent.currentProgress;
    this.currentProgramIndex = this.stateOfComponent.currentProgramIndex;
    this.isOnVideo = this.stateOfComponent.isOnVideo;
    this.previewVideo = this.stateOfComponent.previewVideo;
    this.selectedMonth = this.stateOfComponent.selectedMonth;
    this.selectedYear = this.stateOfComponent.selectedYear;
    this.index = this.stateOfComponent.index;
    this.calendarDay = this.stateOfComponent.calendarDay;
    this.isNextMonth = this.stateOfComponent.isNextMonth;
    this.isPreviousMonth = this.stateOfComponent.isPreviousMonth;
    this.chosenProgram = this.stateOfComponent.chosenProgram;
    this.channelData = this.stateOfComponent.channelData;
    this.timeStart = this.stateOfComponent.timeStart;
    this.isChangeProgram = this.stateOfComponent.isChangeProgram;
    this.isChangeDailySchedule = this.stateOfComponent.isChangeDailySchedule;
    this.isChangeChannel = this.stateOfComponent.isChangeChannel;
    this.contentDaysMonth = this.stateOfComponent.contentDaysMonth;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.dataService.sendData(['publish', this.isShowChannel]);
    this.mediaListOfSequence = this.stateOfComponent.mediaListOfSequence;
    this.commonObject.isShowChannel = this.isShowChannel;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  stopVideo() {
    if (this.previewVideo) {
      this.previewVideo.pause();
      this.previewVideo.src = '';
      this.previewVideo.style.background = '#000';
    }
  }

  /**
   * choose tab
   */
  chooseTabChannel(value: string) {
    // if tab is daily schedule
    if (value == 'Daily') {
      this.isShowChannel = false;
      this.dataService.sendData(['publish', this.isShowChannel]);
      // if tab is channel
    } else {
      this.isShowChannel = true;
      this.dataService.sendData(['publish', this.isShowChannel]);
    }
    this.commonObject.isShowChannel = this.isShowChannel;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * get list of all channels
   */
  getChannels(value?: string) {
    this.channels = new Array<Channel>();
    // get all channels from db
    this.channelService.getChannelsForDSCComponentByProjectId(this.projectId).subscribe(
      channelsData => {
        this.channels = channelsData.map(channelData => {
          return Helper.convertDataChannel(channelData);
        });
        if (value == this.channelName) {
          let index = this.channels.findIndex(channel => channel.id == this.channelData.id);
          this.selectChannel(this.channels[index]);
        } else {
          this.selectChannel(this.channels[0]);
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * select channel
   * @param {Channel} channel selected channel
   */
  selectChannel(channel: Channel) {
    if (!channel) {
      return;
    }
    if (this.isChangeChannel) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes and select another channel?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChannelsWithContentDays();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.selectChannel(channel);
              }
            });
          } else {
            this.isChangeChannel = false;
            this.selectChannel(channel);
          }
        }
      );
    } else {
      if (this.selectedDay) {
        this.selectedDay = undefined;
      }
      this.selectedChannel = channel;
      this.contentDayService.getContentDaysByChannelId(this.selectedChannel.id).subscribe(
        contentDaysData => {
          this.selectedChannel.contentDays = contentDaysData.map(contentDayData => {
            return Helper.convertDataContentDay(contentDayData);
          });
          this.selectedChannel.calendarDays = Helper.getCalendars(this.selectedChannel);
          this.selectedChannel.contentDays.map(contentDay => {
            let date = Helper.getDateByDay(
              contentDay.fullDate.getFullYear(),
              contentDay.fullDate.getMonth() + 1,
              contentDay.fullDate.getDate()
            );
            let index = this.selectedChannel.calendarDays.findIndex(content => content.fullDate.getTime() == date.getTime());
            if (index != -1) {
              this.selectedChannel.calendarDays[index] = contentDay;
            }
          });
          // this.selectedMonth = this.selectedChannel.startDate.getMonth();
          this.selectedMonth = new Date().getMonth();
          this.selectedYear = this.selectedChannel.startDate.getFullYear();
          // get contentDays by month selected and year selected
          this.contentDaysMonth = Helper.getCalendarsByMonthYear(
            this.selectedChannel,
            this.selectedMonth,
            this.selectedChannel.startDate.getFullYear()
          );
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    }
  }

  /**
   * get list all playlists
   */
  fetchPlaylists() {
    this.dailyScheduleService.getDailySchedulesByProjectId(this.projectId).subscribe(
      playlistsData => {
        this.dailySchedules = playlistsData.map(playlistData => {
          return Helper.convertDataPlaylist(playlistData);
        });
        this.selectDailySchedule(this.dailySchedules[0]);
        this.initTimeline();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * update playlist color usage
   */
  updateColors() {
    this.dailyScheduleColors.forEach(color => {
      color.value =
        this.dailySchedules.findIndex(playlist => playlist.id != this.dailyScheduleSelected.id && playlist.color == color.colorCode) > -1
          ? ''
          : null;
    });
  }
  /**
   * select playlist
   * @param {DailySchedule} playlist selected playlist
   */
  selectDailySchedule(playlist: DailySchedule) {
    if (!playlist) {
      return;
    }

    if (this.isChangeDailySchedule) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes and select another daily schedule?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveDailySchedulesWithPrograms();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.selectDailySchedule(playlist);
              }
            });
          } else {
            this.isChangeDailySchedule = false;
            this.selectDailySchedule(playlist);
          }
        }
      );
    } else {
      this.resetDailySchedule();
      this.dailyScheduleSelected = playlist;
      const index = this.dailySchedules.findIndex(dailySchedule => dailySchedule.id == this.dailyScheduleSelected?.id);
      if (index != -1) {
        this.dailySchedules[index] = this.dailyScheduleSelected;
      }
      this.dailyScheduleProgramService.getProgramsByDailyScheduleId(playlist.id).subscribe(
        programsData => {
          this.dailyScheduleSelected.mediaList = programsData
            .map(programData => {
              return Helper.convertDataProgram(programData);
            })
            .filter(program => program.media);
          this.chosenProgram = undefined;
          this.updateColors();
          this.previewVideo = document.getElementById('previewVideo') as HTMLVideoElement;
          while (this.previewVideo !== null && this.previewVideo.firstChild) {
            this.previewVideo.removeChild(this.previewVideo.firstChild);
          }
          if (this.dailyScheduleSelected.mediaList) {
            this.dailyScheduleSelected.mediaList.forEach(program => {
              if (program.media) {
                this.addSourceToVideo(this.previewVideo, program.media.url, program.media.type);
              }
            });
          }
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    }
  }

  /**
   *
   * @param videoElement
   * @param src
   * @param type
   */
  addSourceToVideo(videoElement: HTMLVideoElement, src: string, type: string) {
    let source = document.createElement('source');
    source.src = src;
    source.type = 'video/' + type;
    videoElement?.appendChild(source);
  }

  /**
   * select playlist
   * @param {Program} program selected program
   */
  selectProgram(program: Program, index: number) {
    this.chosenProgram = program;
    this.selectedProgram = program;
    this.dailyScheduleSelected.mediaList.forEach((program, i) => {
      let element = document.getElementById(`${i}`);
      element.style.background = this.dailyScheduleSelected.color;
    });
    const compareColorSelected = item => {
      return item.colorCode == this.dailyScheduleSelected.color;
    };
    document.getElementById(`${index}`).style.background = this.dailyScheduleColors.find(compareColorSelected)?.colorSelected;
  }

  /**
   * Calculate duration of sequence
   * @returns sequenceDuration: number
   */
  calculateDuration(): number {
    let durationSequence: number = 0;
    this.mediaListOfSequence?.forEach(data => {
      if (Helper.isVideo(data)) {
        durationSequence += +(data.getMedia().out - data.getMedia().in);
      } else if (Helper.isImage(data)) {
        durationSequence += +data.getMedia().duration;
      }
    });
    return durationSequence;
  }

  /**
   * select day
   * @param {ContentDay} day selected day
   */
  selectDay(day: ContentDay) {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.selectedDay = day;
    if (this.selectedDay.playlist) {
      this.selectedDay.playlist = this.dailySchedules.find(playlist => playlist.id == this.selectedDay.playlist.id);
    }
  }

  /**
   * show previous month
   */
  showPrevMonth() {
    // return if < start date of channel
    if (this.selectedYear == this.selectedChannel.startDate.getFullYear()) {
      if (this.selectedMonth <= this.selectedChannel.startDate.getMonth()) {
        this.isPreviousMonth = true;
        return;
      }
    }
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonth = this.selectedMonth <= 0 ? 11 : this.selectedMonth - 1;
    this.selectedYear = this.selectedMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(this.selectedChannel, this.selectedMonth, this.selectedYear);
  }

  /**
   * select month
   * @param month any
   */
  selectMonth(month: any) {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(this.selectedChannel, this.selectedMonth, this.selectedYear);
  }

  /**
   * show next month
   */
  showNextMonth() {
    // return if > finish date of channel
    let dateEnd = _.cloneDeep(this.selectedChannel.startDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd.setDate(dateEnd.getDate() - 1);
    if (this.selectedYear == this.selectedChannel.startDate.getFullYear() + Constant.MAX_YEAR) {
      if (this.selectedMonth >= dateEnd.getMonth()) {
        this.isNextMonth = true;
        return;
      }
    }
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonth = this.selectedMonth >= 11 ? 0 : this.selectedMonth + 1;
    this.selectedYear = this.selectedMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYear(this.selectedChannel, this.selectedMonth, this.selectedYear);
  }

  /**
   * save data daily schedule
   */
  private saveDailySchedulesWithPrograms() {
    this.dailyScheduleService.updateProgramsOfDailySchedule(this.dailyScheduleSelected).subscribe(
      () => {
        this.toast.success(Constant.SAVE_SUCCESS, '');
        this.isChangedData = false;
        this.isChangeDailySchedule = false;
        this.saveDataSuccess.emit(true);
        this.selectDailySchedule(this.dailyScheduleSelected);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * save data channel
   */
  private saveChannelsWithContentDays() {
    let channelsSave = this.channels.map(channel => {
      return Helper.convertDataChannelForward(channel, this.commonObject.setting);
    });
    this.channelService.updateContentDays(channelsSave).subscribe(
      () => {
        this.toast.success(Constant.SAVE_SUCCESS, '');
        this.isChangedData = false;
        this.isChangeChannel = false;
        this.saveDataSuccess.emit(true);
        this.selectChannel(this.selectedChannel);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * key code map
   */
  keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    83: false // S
  };
  /**
   * key down
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save data
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        if (this.isShowChannel) {
          this.saveChannelsWithContentDays();
        } else {
          this.saveDailySchedulesWithPrograms();
        }
      }
    }
  }

  /**
   * key up
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (e.keyCode == 17) {
      this.keyCodeMap[17] = false;
    }
    if (e.keyCode == 18) {
      this.keyCodeMap[18] = false;
    }
    if (e.keyCode == 83) {
      this.keyCodeMap[83] = false;
    }
  }

  /**
   * increase timeline division by one step until max value
   */
  zoomIn() {
    if (this.timelineDivision >= 1440 || this.isPlayVideo) {
      return;
    }
    let valueTimeline = 0;
    switch (this.timelineDivision) {
      case TimeDivision.LEVEL_1:
        this.timelineDivision = TimeDivision.LEVEL_2;
        this.currentProgress = this.currentProgress * 4;
        valueTimeline = 30;
        break;
      case TimeDivision.LEVEL_2:
        this.timelineDivision = TimeDivision.LEVEL_3;
        this.currentProgress = this.currentProgress * 2;
        valueTimeline = 15;
        break;
      case TimeDivision.LEVEL_3:
        this.timelineDivision = TimeDivision.LEVEL_4;
        this.currentProgress = this.currentProgress * 3;
        valueTimeline = 5;
        break;
      case TimeDivision.LEVEL_4:
        this.timelineDivision = TimeDivision.LEVEL_5;
        this.currentProgress = this.currentProgress * 2.5;
        valueTimeline = 2;
        break;
      case TimeDivision.LEVEL_5:
        this.timelineDivision = TimeDivision.LEVEL_6;
        this.currentProgress = this.currentProgress * 2;
        valueTimeline = 1;
        break;
      default:
        break;
    }
    // update time line
    this.updateTimeline(valueTimeline);
    // calculator startPixel and durationPixel of program
    this.updateTimePrograms(valueTimeline);
  }

  /**
   * update time list program when zoom
   * @param durationRate duration rate
   */
  updateTimePrograms(durationRate: number) {
    Constant.PLAY_SEGMENT = durationRate;
  }

  /**
   * decrease timeline division by one step until min value
   */
  zoomOut() {
    if (this.timelineDivision <= this.TIMELINE_STEP || this.isPlayVideo) {
      return;
    }
    let valueTimeline = 0;
    switch (this.timelineDivision) {
      case TimeDivision.LEVEL_2:
        this.timelineDivision = TimeDivision.LEVEL_1;
        this.currentProgress = this.currentProgress / 4;
        valueTimeline = 120;
        break;
      case TimeDivision.LEVEL_3:
        this.timelineDivision = TimeDivision.LEVEL_2;
        this.currentProgress = this.currentProgress / 2;
        valueTimeline = 30;
        break;
      case TimeDivision.LEVEL_4:
        this.timelineDivision = TimeDivision.LEVEL_3;
        this.currentProgress = this.currentProgress / 3;
        valueTimeline = 15;
        break;
      case TimeDivision.LEVEL_5:
        this.timelineDivision = TimeDivision.LEVEL_4;
        this.currentProgress = this.currentProgress / 2.5;
        valueTimeline = 5;
        break;
      case TimeDivision.LEVEL_6:
        this.timelineDivision = TimeDivision.LEVEL_5;
        this.currentProgress = this.currentProgress / 2;
        valueTimeline = 2;
        break;
      default:
        break;
    }
    // update time line
    this.updateTimeline(valueTimeline);
    // calculator startPixel and durationPixel of program
    this.updateTimePrograms(valueTimeline);
  }

  /**
   * initialize timeline values
   */
  initTimeline() {
    let minute = this.TIMELINE_INIT;
    this.channelTimestamps = new Array<Timestamp>();
    while (minute <= 1440) {
      this.channelTimestamps.push(new Timestamp(minute, true));
      minute += this.TIMELINE_INIT;
    }
  }
  /**
   * update timeline
   * @param value value timestamp
   */
  updateTimeline(value: number) {
    let minute = value;
    this.channelTimestamps = new Array<Timestamp>();
    while (minute <= 1440) {
      this.channelTimestamps.push(new Timestamp(minute, true));
      minute += value;
    }
  }

  /**
   * change the start time and duration of selected program
   * @param e mouse event
   * @param timeline timeline element
   */
  changeStartTimeAndDuration(e: MouseEvent, timeline: HTMLElement) {
    if (this.dailyScheduleSelected?.mediaList) {
      if (this.isMouseDownProgram && this.selectedProgram) {
        this.isChangeProgram = true;
        this.isChangedData = true;
        this.isChangeDailySchedule = true;
        let index = this.dailyScheduleSelected.mediaList.findIndex(program => program.symbol == this.selectedProgram.symbol);
        if (this.isChangeDuration) {
          this.selectedProgram.durationPixel = this.oldDurationPixel + e.pageY - this.startPosY;
          if (this.dailyScheduleSelected.mediaList[index + 1]) {
            if (
              this.selectedProgram.startPixel + this.selectedProgram.durationPixel >
              this.dailyScheduleSelected.mediaList[index + 1].startPixel
            ) {
              this.selectedProgram.durationPixel =
                this.dailyScheduleSelected.mediaList[index + 1].startPixel - this.selectedProgram.startPixel;
              return;
            }
          } else {
            if (this.selectedProgram.startPixel + this.selectedProgram.durationPixel > Helper.getMaxHeight()) {
              this.selectedProgram.durationPixel = Helper.getMaxHeight() - this.selectedProgram.startPixel;
            }
          }
        } else if (this.isChangeStart) {
          let oldValue = this.selectedProgram.startPixel;
          this.selectedProgram.startPixel = this.oldStartPixel + e.pageY - this.startPosY;
          if (
            this.dailyScheduleSelected.mediaList.findIndex(
              program => this.isOverlapBottom(this.selectedProgram, program) || this.isOverlapTop(this.selectedProgram, program)
            ) > -1
          ) {
            if (this.dailyScheduleSelected.mediaList[index - 1]) {
              if (
                this.dailyScheduleSelected.mediaList[index - 1].startPixel < this.selectedProgram.durationPixel &&
                this.selectedProgram.startPixel < oldValue
              ) {
                this.selectedProgram.startPixel =
                  this.dailyScheduleSelected.mediaList[index - 1].startPixel +
                  this.dailyScheduleSelected.mediaList[index - 1].durationPixel;
              }
              if (this.dailyScheduleSelected.mediaList[index - 2]) {
                if (
                  this.dailyScheduleSelected.mediaList[index - 1].startPixel -
                    this.dailyScheduleSelected.mediaList[index - 2].startPixel -
                    this.dailyScheduleSelected.mediaList[index - 2].durationPixel <
                    this.selectedProgram.durationPixel &&
                  this.selectedProgram.startPixel < oldValue
                ) {
                  this.selectedProgram.startPixel =
                    this.dailyScheduleSelected.mediaList[index - 1].startPixel +
                    this.dailyScheduleSelected.mediaList[index - 1].durationPixel;
                }
              }
            }
            if (this.dailyScheduleSelected.mediaList[index + 1]) {
              if (
                Helper.getMaxHeight() -
                  this.dailyScheduleSelected.mediaList[index + 1].startPixel -
                  this.dailyScheduleSelected.mediaList[index + 1].durationPixel <
                  this.selectedProgram.durationPixel &&
                this.selectedProgram.startPixel > oldValue
              ) {
                this.selectedProgram.startPixel =
                  this.dailyScheduleSelected.mediaList[index + 1].startPixel - this.selectedProgram.durationPixel;
              }
              if (this.dailyScheduleSelected.mediaList[index + 2]) {
                if (
                  this.dailyScheduleSelected.mediaList[index + 2].startPixel -
                    this.dailyScheduleSelected.mediaList[index + 1].startPixel -
                    this.dailyScheduleSelected.mediaList[index + 1].durationPixel <
                    this.selectedProgram.durationPixel &&
                  this.selectedProgram.startPixel > oldValue
                ) {
                  this.selectedProgram.startPixel =
                    this.dailyScheduleSelected.mediaList[index + 1].startPixel - this.selectedProgram.durationPixel;
                }
              }
            }
          } else {
            this.dailyScheduleSelected.mediaList = this.dailyScheduleSelected.mediaList.sort(function(program1, program2) {
              return program1.startPixel - program2.startPixel;
            });
          }
        }
      } else {
        for (const program of this.dailyScheduleSelected.mediaList) {
          let distanceToEnd = e.pageY - (program.durationPixel + program.startPixel + timeline.getBoundingClientRect().top);
          let distanceToStart = e.pageY - (program.startPixel + timeline.getBoundingClientRect().top);

          // cursor at the bottom edge of program
          if (-5 < distanceToEnd && distanceToEnd < 5) {
            this.renderer.setStyle(e.target, 'cursor', 'n-resize');
            this.selectedProgram = program;
            this.isChangeDuration = true;
            this.isChangeStart = false;
            return;
          } else if (0 < distanceToStart && distanceToEnd < 0) {
            // cursor inside the program
            this.renderer.setStyle(e.target, 'cursor', 'move');
            this.selectedProgram = program;
            this.isChangeStart = true;
            this.isChangeDuration = false;
            return;
          } else {
            this.renderer.setStyle(e.target, 'cursor', 'default');
            this.selectedProgram = undefined;
          }
        }
      }
      this.dailyScheduleSelected.mediaList = this.dailyScheduleSelected.mediaList.sort(function(program1, program2) {
        return program1.startPixel - program2.startPixel;
      });
    }
  }

  /**
   * check if the dragged program is overlapped with another program on top
   * @param dragged dragged program
   * @param nearby another program
   */
  public isOverlapTop(dragged: Program, nearby: Program): boolean {
    let value = Helper.getMaxHeight();

    if (
      dragged.symbol != nearby.symbol &&
      dragged.startPixel < nearby.startPixel + nearby.durationPixel &&
      dragged.startPixel > nearby.startPixel
    ) {
      this.selectedProgram.startPixel = nearby.startPixel + nearby.durationPixel;
      return true;
    } else if (dragged.startPixel < 0) {
      this.selectedProgram.startPixel = 0;
      return true;
    } else if (dragged.startPixel + dragged.durationPixel > value) {
      this.selectedProgram.startPixel = value - dragged.durationPixel;
      return true;
    }
    return false;
  }

  /**
   * check if the dragged program is overlapped with another program at bottom
   * @param dragged dragged program
   * @param nearby dragged program
   */
  public isOverlapBottom(dragged: Program, nearby: Program): boolean {
    let value = Helper.getMaxHeight();
    if (
      dragged.symbol != nearby.symbol &&
      nearby.startPixel < dragged.startPixel + dragged.durationPixel &&
      nearby.startPixel >= dragged.startPixel
    ) {
      this.selectedProgram.startPixel = nearby.startPixel - dragged.durationPixel;
      return true;
    } else if (dragged.durationPixel <= 0) {
      this.selectedProgram.startPixel = 0;
      return true;
    } else if (dragged.startPixel + dragged.durationPixel > value) {
      this.selectedProgram.startPixel = value - dragged.durationPixel;
      return true;
    }
    return false;
  }

  /**
   * mouse down action
   * @param e mouse event
   */
  onMouseDownProgram(e: MouseEvent) {
    if (this.isPlayVideo || !this.selectedProgram) {
      return;
    }
    this.isMouseDownProgram = true;
    this.startPosY = e.pageY;
    this.oldDurationPixel = this.selectedProgram.durationPixel;
    this.oldStartPixel = this.selectedProgram.startPixel;
    if (this.selectedProgram) {
      this.oldStartTime = this.selectedProgram.startTime;
    }
  }

  /**
   * mouse up subscriber
   * @param e mouse event
   */
  @HostListener('mouseup', ['$event'])
  mouseUp(e) {
    this.isMouseDownProgram = false;
    this.startPosY = 0;
    this.isChangeStart = false;
    this.isChangeDuration = false;
    this.selectedProgram = undefined;
    this.renderer.setStyle(e.target, 'cursor', 'default');
  }

  /**
   * drop a video to timeline
   * @param e event
   * @param index timeline element index
   */
  async dropMedia(e, index: number) {
    if (
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(e.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    e.preventDefault();
    this.restoreBackground(e);
    let obj = JSON.parse(e.dataTransfer.getData(Constant.MEDIA_VALUE));
    if (!obj) {
      return;
    }
    // convert to Media
    obj.name = `${obj.name}_${this.getNameOriginal(obj.mediaNameEncode)}`;
    let media = Helper.convertMediaData(obj);
    // show error if media is not video or sequence
    if (media.type != TypeMediaFileEnum.MP4 && media.type != TypeMediaFileEnum.SEQ) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Only video or sequence is allowed.' } });
      return;
    }
    this.mediaListOfSequence = await this.convertMediaListOfSequence(media);
    if (media.type == TypeMediaFileEnum.SEQ && _.size(this.mediaListOfSequence) == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Sequence has no data.' } });
      return;
    }
    // duration when drop zoom out/ zoom in
    media.durationDisplay = media.duration;
    media.duration = Constant.PLAY_SEGMENT;
    // validate overlap before dropping
    let startPixel = index * Constant.SEGMENT_HEIGHT;
    let durationPixel = (media.duration / Constant.PLAY_SEGMENT) * Constant.SEGMENT_HEIGHT;
    if (
      this.dailyScheduleSelected.mediaList.findIndex(
        program => startPixel <= program.startPixel && durationPixel + startPixel > program.startPixel
      ) > -1 ||
      this.dailyScheduleSelected.mediaList.findIndex(
        program => startPixel > program.startPixel && startPixel < program.startPixel + program.durationPixel
      ) > -1
    ) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Cannot drop video here.' } });
      return;
    }
    this.isChangedData = true;
    this.isChangeDailySchedule = true;
    this.dailyScheduleSelected.mediaList.push(new Program(media, index * Constant.PLAY_SEGMENT, media.duration));
  }

  /**
   * Get name original name for media
   *
   * @param mediaName
   * @returns
   */
  private getNameOriginal(mediaName: string): string {
    let index = mediaName.lastIndexOf('_');
    if (index != -1) {
      return mediaName.substring(index + 1);
    }
    return mediaName;
  }

  /**
   * prevent dropping
   * @param e event
   */
  preventDrop(e) {
    if (!this.dailyScheduleSelected) {
      return;
    }
    e.preventDefault();
  }

  /**
   * change timeline element background when dragging
   * @param e event
   */
  dimBackground(e) {
    e.target.style.background = '#c8c8c8';
  }

  /**
   * restore timeline element background after dropping
   * @param e event
   */
  restoreBackground(e) {
    e.target.style.background = '#e5e5e5';
  }

  /**
   * set data content day
   * @param contentDay ContentDay
   * @param date Date
   */
  setDataContentDay(contentDay: ContentDay, date: Date) {
    let indexRepeat = this.selectedChannel.calendarDays.findIndex(calendarDay => calendarDay.fullDate.getTime() === date.getTime());
    this.selectedChannel.calendarDays[indexRepeat].playlist = Helper.convertDataPlaylist(contentDay.playlist);
  }

  /**
   * set recurrence of the playlist in selected day
   * @param {ContentDay} day selected day
   */
  setRepeat(day: ContentDay) {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPlaylistRecurrenceComponent,
      {
        data: {
          contentDay: Object.assign({}, day),
          startDate: this.selectedChannel.startDate,
          channel: this.selectedChannel,
          playlist: this.dailyScheduleSelected,
          isChangeDailySchedule: this.isChangeDailySchedule
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        // let contentDay = Helper.convertContentDayData(result[0]);
        let contentDay = result[0];
        // One day
        if (!contentDay.isRepeated) {
          this.setDataContentDay(contentDay, contentDay.fullDate);
          // Repeat
        } else {
          // repeat mode: Every Day
          if (contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
            this.handleEveryDayMode(contentDay);
            // repeat mode: Every Week
          } else if (contentDay.repeatMode == RepeatModeEnum.EVERY_WEEK) {
            let listDay = [];
            // get day selected
            result[1].forEach((day, index) => {
              if (day[1]) {
                listDay.push(index);
              }
            });
            this.handleEveryWeekMode(contentDay, listDay);
          }
        }
        this.isChangedData = true;
        this.isChangeChannel = true;
        this.selectedDay = undefined;
        this.selectedChannel.contentDays = this.selectedChannel.calendarDays.filter(contentDay => contentDay.playlist);
        this.contentDaysMonth.map(contentDay => {
          let index = this.selectedChannel.contentDays.findIndex(content => content.fullDate.getTime() == contentDay.fullDate.getTime());
          if (index != -1) {
            contentDay.playlist = this.selectedChannel.contentDays[index].playlist;
          }
        });
      }
    );
  }
  /**
   * handle repeat mode: Every Week
   * @param contentDay content of day
   * @param listDay
   */
  handleEveryWeekMode(contentDay: any, listDay: any) {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            if (listDay.findIndex(index => date.getDay() == index) != -1) {
              this.setDataContentDay(contentDay, date);
            }
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              if (listDay.findIndex(index => date.getDay() == index) != -1) {
                this.setDataContentDay(contentDay, date);
              }
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
    }
  }
  /**
   * handle repeat mode: Every Day
   * @param contentDay content of day
   */
  handleEveryDayMode(contentDay: any) {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              this.setDataContentDay(contentDay, date);
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
    }
  }

  /**
   * add a new playlist
   */
  addPlaylist() {
    if (this.dailySchedules.length >= 10) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'You can create no more than 10 Daily Schedules.' }
      });
      return;
    }
    let newPlaylist = new DailySchedule('', '', this.projectId);
    let index = this.dailyScheduleColors.findIndex(color => color.colorCode === this.dailyScheduleSelected?.color);
    if (index != -1) {
      this.dailyScheduleColors[index].value = '';
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPlaylistComponent,
      {
        data: {
          title: 'Add Daily Schedule',
          playlist: newPlaylist,
          playlists: this.dailySchedules,
          colors: this.dailyScheduleColors
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.dailyScheduleService.addDailySchedule(Helper.convertDataPlaylistForward(<DailySchedule>result['playlist'])).subscribe(
            playlistData => {
              this.dailySchedules.push(Helper.convertDataPlaylist(playlistData));
              this.selectDailySchedule(this.dailySchedules[this.dailySchedules.length - 1]);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * edit selected playlist
   * @param playlist selected playlist
   */
  editDailySchedule(playlist: DailySchedule) {
    if (!playlist) {
      return;
    }
    let oldPlaylist = Object.assign({}, playlist);
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPlaylistComponent,
      {
        data: {
          title: 'Edit Daily Schedule',
          playlist: Object.assign({}, playlist),
          playlists: this.dailySchedules,
          colors: this.dailyScheduleColors
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        this.dailyScheduleService.editDailySchedule(Helper.convertDataPlaylistForward(<DailySchedule>result['playlist'])).subscribe(
          playlistData => {
            let playlistEdit = Helper.convertDataPlaylist(playlistData);
            this.selectDailySchedule(playlistEdit);
            if (oldPlaylist.name != playlistEdit.name || oldPlaylist.color != playlistEdit.color) {
              this.contentDaysMonth
                .filter(contentDay => contentDay.playlist?.name == oldPlaylist.name)
                .map(contentDayData => {
                  contentDayData.playlist.name = playlistEdit.name;
                  contentDayData.playlist.color = playlistEdit.color;
                });
            }
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      }
    );
  }

  /**
   * delete playlist
   */
  deleteDailySchedule() {
    if (this.dailySchedules.length == 0) {
      return;
    }
    if (this.dailyScheduleSelected) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to delete ${this.dailyScheduleSelected.name}?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.dailyScheduleService.deleteDailySchedule(this.dailyScheduleSelected).subscribe(
              () => {
                this.dailyScheduleSelected.mediaList = [];
                this.channels.forEach(channel => {
                  let contentDays = channel?.contentDays.filter(contentDay => contentDay.playlist?.id == this.dailyScheduleSelected.id);
                  let contentDaysNew = contentDays.filter(contentDayData => contentDayData?.id);
                  contentDays.forEach(contentDayData => {
                    let indexContentDay = channel.calendarDays.findIndex(
                      contentDay => contentDay.fullDate.getTime() == contentDayData.fullDate.getTime()
                    );
                    if (indexContentDay != -1) {
                      channel.calendarDays[indexContentDay].playlist = undefined;
                    }
                  });
                  if (contentDaysNew.length > 0) {
                    this.contentDayService.deleteContentDays(contentDaysNew).toPromise();
                  }
                });
                let selectedIndex = this.dailySchedules.findIndex(playlist => playlist.id == this.dailyScheduleSelected.id);
                if (selectedIndex != -1) {
                  this.dailySchedules.splice(selectedIndex, 1);
                  this.isChangeDailySchedule = false;
                  if (this.dailySchedules[0]) {
                    this.selectDailySchedule(this.dailySchedules[0]);
                  } else {
                    this.dailyScheduleSelected = undefined;
                  }
                }
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
              }
            );
          }
        }
      );
    } else {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select daily schedule.' } });
      return;
    }
  }

  /**
   * delete program
   */
  deleteProgram() {
    if (this.dailyScheduleSelected.mediaList.length == 0) {
      return;
    }
    if (this.chosenProgram) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to delete ${this.chosenProgram?.media?.name}?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            let index = this.dailyScheduleSelected.mediaList.findIndex(program => program.symbol == this.chosenProgram.symbol);
            if (index != -1) {
              this.dailyScheduleSelected.mediaList.splice(index, 1);
              this.isChangedData = true;
              this.isChangeDailySchedule = true;
              this.chosenProgram = undefined;
            }
          }
        }
      );
    }
  }

  /**
   * delete content day
   */
  deleteContentDay() {
    if (!this.selectedDay.playlist) {
      return;
    }
    if (this.selectedDay) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to delete ${this.selectedDay.playlist.name}?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            let indexDelete = this.selectedChannel.contentDays.findIndex(
              calendar => calendar.fullDate.getTime() == this.selectedDay.fullDate.getTime()
            );
            if (indexDelete != -1) {
              let index = this.contentDaysMonth.findIndex(
                contentDay => contentDay.fullDate.getTime() == this.selectedChannel.contentDays[indexDelete].fullDate.getTime()
              );
              if (index != -1) {
                this.contentDaysMonth[index] = new ContentDay(
                  this.selectedDay.fullDate.getDay(),
                  this.selectedDay.fullDate,
                  false,
                  undefined,
                  undefined
                );
                let indexDeleteCalendar = this.selectedChannel.calendarDays.findIndex(
                  contentDay => contentDay.fullDate.getTime() == this.selectedChannel.contentDays[indexDelete].fullDate.getTime()
                );
                if (indexDeleteCalendar != -1) {
                  this.selectedChannel.calendarDays[indexDeleteCalendar].playlist = undefined;
                }
                this.selectedChannel.contentDays.splice(indexDelete, 1);
                this.isChangedData = true;
                this.isChangeChannel = true;
                this.selectedDay = undefined;
              }
            }
          }
        }
      );
    }
  }

  /**
   * update channel content after editing playlist
   */
  updateChannelContent() {
    this.channels.forEach(channel => {
      channel.calendarDays.forEach(contentDay => {
        contentDay.playlist = this.dailySchedules.find(playlist => playlist?.id == contentDay?.playlist?.id);
      });
    });
  }

  /**
   * add a new channel
   */
  addChannel() {
    if (this.channels.length >= 10) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'You can create no more than 10 Channels.' } });
      return;
    }
    let newChannel = new Channel('', new Date(), this.projectId);
    this.editChannel(newChannel, false);
  }

  /**
   * edit selected channel
   * @param channel selected channel
   * @param isEdit true when edit, false when add new
   */
  editChannel(channel: Channel, isEdit: boolean) {
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChannelComponent,
      {
        data: {
          title: isEdit ? 'Edit Channel' : 'Add Channel',
          channel: isEdit ? Object.assign({}, channel) : channel
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        if (isEdit) {
          this.channelService.editChannel(Helper.convertDataChannelForward(result, this.commonObject.setting)).subscribe(
            channelData => {
              let channelEdit = Helper.convertDataChannel(channelData);
              let indexChannel = this.channels.findIndex(channel => channel.id == channelEdit.id);
              this.channels[indexChannel] = channelEdit;
              this.selectChannel(this.channels[indexChannel]);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        } else {
          this.channelService.addChannel(Helper.convertDataChannelForward(result, this.commonObject.setting)).subscribe(
            channelData => {
              let channelNew = Helper.convertDataChannel(channelData);
              this.channels.push(channelNew);
              this.selectChannel(this.channels[this.channels.length - 1]);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * delete channel
   */
  deleteChannel() {
    if (this.channels.length == 0) {
      return;
    }
    if (this.selectedChannel) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to delete ${this.selectedChannel.name}?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.channelService.deleteChannel(this.selectedChannel.id).subscribe(
              () => {
                // this.getChannels();
                let indexChannel = this.channels.findIndex(channel => channel.id == this.selectedChannel.id);
                if (indexChannel != -1) {
                  this.channels.splice(indexChannel, 1);
                }
                if (this.channels[0]) {
                  this.isChangeChannel = false;
                  this.selectChannel(this.channels[0]);
                } else {
                  this.selectedChannel.calendarDays = new Array<ContentDay>();
                  this.contentDaysMonth = new Array<ContentDay>();
                  this.selectedMonth = undefined;
                  this.selectedYear = undefined;
                }
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
              }
            );
          }
        }
      );
    } else {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select channel.' } });
      return;
    }
  }

  /**
   * play video
   */
  playVideo() {
    this.isPauseVideo = false;
    this.isPlayVideo = true;
    var time = setInterval(() => {
      if (Math.round(this.timeStart) == Constant.SECONDS_OF_ONE_DAY) {
        this.resetDailySchedule();
        return;
      }
      if (!this.isNextVideoOn && this.currentProgramIndex == -1) {
        this.currentProgramIndex = 0;
      }
      if (!this.isNextVideoOn) {
        let programIndex = this.dailyScheduleSelected.mediaList.find(
          program =>
            Math.round(this.timeStart) > program.startTime * 60 &&
            Math.round(this.timeStart) < Math.round((program.duration + program.startTime) * 60)
        );
        if (!programIndex) {
          this.isOnVideo = false;
          this.stopVideo();
        } else {
          let indexProgramCurrent = this.dailyScheduleSelected.mediaList.findIndex(program => program.symbol == programIndex.symbol);
          if (!this.isNextVideoOn) {
            this.currentProgramIndex = indexProgramCurrent;
            if (this.previewVideo.src == Constant.BACKEND_URL) {
              this.previewVideo.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex]?.media?.url;
            }
          }
          if (this.isChangeProgram) {
            let program = this.dailyScheduleSelected.mediaList[indexProgramCurrent];
            if (Math.round(this.timeStart) > Math.round(program?.media?.getMedia().duration + program?.startTime * 60)) {
              this.previewVideo.currentTime = Math.round(this.timeStart) % program.media.getMedia().duration;
            } else {
              this.previewVideo.currentTime = Math.round(this.timeStart - program.startTime * 60);
            }
            this.previewVideo.src = this.dailyScheduleSelected.mediaList[indexProgramCurrent]?.media?.url;
            this.isChangeProgram = false;
          }
          this.isOnVideo = true;
        }
        if (Math.round(this.timeStart) == Math.round(this.dailyScheduleSelected.mediaList[this.currentProgramIndex]?.startTime * 60)) {
          this.isOnVideo = true;
          this.previewVideo.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].media.url;
        }
      } else {
        this.isOnVideo = true;
        this.isNextVideoOn = false;
      }

      if (this.isOnVideo) {
        this.previewVideo.play();
      } else {
        this.stopVideo();
      }
      // this.currentProgress++;
      this.currentProgress += Constant.CELL_HEIGHT / this.UNIT_TIME / Constant.PLAY_SEGMENT;
      this.timeStart = (this.currentProgress * Constant.PLAY_SEGMENT * this.UNIT_TIME) / Constant.CELL_HEIGHT;
    }, 1000);
    this.intervalList.push(time);
  }

  /**
   * reset list daily schedule
   */
  resetDailySchedule() {
    this.clearIntervalList();
    this.currentProgress = 0;
    this.currentProgramIndex = -1;
    this.isNextVideoOn = false;
    this.isPlayVideo = false;
    this.isPauseVideo = false;
    this.previewVideo.pause();
    this.previewVideo.src = '';
    this.previewVideo.style.background = '#000';
    this.timeStart = 0;
  }

  /**
   * clear interval list
   */
  public clearIntervalList() {
    if (this.intervalList.length > 0) {
      this.intervalList.forEach(time => {
        clearInterval(time);
      });
    }
    this.intervalList = [];
  }

  /**
   * pause video
   */
  pauseVideo() {
    this.previewVideo.pause();
    this.isPauseVideo = true;
    this.isPlayVideo = false;
    this.clearIntervalList();
  }

  /**
   * update progress
   */
  updateProgress() {
    if (this.dailyScheduleSelected?.mediaList && this.currentProgramIndex != -1) {
      let programIndex = this.dailyScheduleSelected.mediaList[this.currentProgramIndex];
      if (Math.round(this.timeStart) == Math.round((programIndex?.startTime + programIndex?.duration) * 60)) {
        this.previewVideo.pause();
        this.previewVideo.currentTime = 0;
        this.currentProgramIndex++;
        if (Math.round(this.timeStart) == Math.round(this.dailyScheduleSelected.mediaList[this.currentProgramIndex]?.startTime * 60)) {
          this.previewVideo.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].media.url;
        } else {
          this.previewVideo.src = '';
          this.previewVideo.style.background = '#000';
        }
        this.isOnVideo = false;
        this.clearIntervalList();
        this.playVideo();
      }
    }
  }

  /**
   * previous video
   */
  previousVideo() {
    if (!this.isPlayVideo) {
      return;
    }
    this.isNextVideoOn = true;
    this.isPlayVideo = false;
    this.isPauseVideo = false;
    this.currentProgramIndex = this.currentProgramIndex <= 0 ? 0 : this.currentProgramIndex - 1;
    this.currentProgress = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].startPixel;
    let element = document.getElementById('program-scroll') as HTMLElement;
    if (this.currentProgramIndex >= 0) {
      element.scrollBy({
        top:
          this.dailyScheduleSelected.mediaList[this.currentProgramIndex]?.startPixel -
          this.dailyScheduleSelected.mediaList[this.currentProgramIndex + 1]?.startPixel,
        left: 0,
        behavior: 'smooth'
      });
    }
    this.previewVideo.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].media.url;
    this.clearIntervalList();
    this.playVideo();
  }

  /**
   * next video
   */
  nextVideo() {
    if (!this.isPlayVideo) {
      return;
    }
    if (this.currentProgramIndex >= this.dailyScheduleSelected.mediaList.length - 1) {
      this.currentProgramIndex = this.dailyScheduleSelected.mediaList.length - 1;
    }
    this.isNextVideoOn = true;
    this.isPlayVideo = false;
    this.isPauseVideo = false;
    let element = document.getElementById('program-scroll') as HTMLElement;
    if (this.currentProgramIndex < this.dailyScheduleSelected.mediaList.length - 1) {
      element.scrollBy({
        top:
          this.dailyScheduleSelected.mediaList[this.currentProgramIndex + 1]?.startPixel -
          this.dailyScheduleSelected.mediaList[this.currentProgramIndex]?.startPixel -
          66,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (
      this.currentProgramIndex != -1 &&
      this.currentProgress < this.dailyScheduleSelected.mediaList[this.currentProgramIndex].startPixel
    ) {
      this.currentProgramIndex = this.currentProgramIndex;
    } else {
      this.currentProgramIndex =
        this.currentProgramIndex >= this.dailyScheduleSelected.mediaList.length - 1
          ? this.dailyScheduleSelected.mediaList.length - 1
          : this.currentProgramIndex + 1;
    }
    this.currentProgress = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].startPixel;
    this.previewVideo.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].media.url;
    this.clearIntervalList();
    this.playVideo();
  }

  /**
   * loop video
   */
  loopVideo() {
    this.currentProgramIndex =
      this.currentProgramIndex >= this.dailyScheduleSelected.mediaList.length - 1 ? 0 : this.currentProgramIndex + 1;
    this.mediaplayer.nativeElement.src = this.dailyScheduleSelected.mediaList[this.currentProgramIndex].media.url;
    this.mediaplayer.nativeElement.play();
  }

  /**
   * publish data
   */
  publishData() {
    if (!this.isPublish) {
      this.isPublish = true;
      if (this.isChangeChannel) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: `Do you want to save changes and publish data?`,
              button1: 'Yes',
              button2: 'Cancel',
              title: 'Confirmation'
            }
          },
          result => {
            if (result) {
              this.saveChannelsWithContentDays();
              const sub = this.saveDataSuccess.subscribe(isSuccess => {
                sub.unsubscribe();
                if (isSuccess) {
                  this.isPublish = false;
                  this.isChangeChannel = false;
                  this.publishData();
                }
              });
            } else {
              this.isPublish = false;
            }
          }
        );
      } else {
        if (this.selectedChannel.calendarDays.every(contentDay => !contentDay.playlist)) {
          this.dialogService.showDialog(
            DialogMessageComponent,
            { data: { title: 'Error', text: 'There is no data to publish.' } },
            result => {
              this.isPublish = false;
            }
          );
          return;
        }
        let date = Helper.getDateByDay(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
        let contentDays = this.selectedChannel.calendarDays.filter(contentDay => contentDay.playlist);
        let index = contentDays.findIndex(
          contentDay =>
            Helper.getDateByDay(contentDay.fullDate.getFullYear(), contentDay.fullDate.getMonth() + 1, contentDay.fullDate.getDate()) >=
            date
        );
        if (index == -1) {
          this.dialogService.showDialog(
            DialogMessageComponent,
            { data: { title: 'Error', text: 'The selected channel has no valid data to publish.' } },
            result => {
              this.isPublish = false;
            }
          );
          return;
        }
        this.dialogService.showDialog(DialogPublishDataSignageChannelComponent, { data: { channel: this.selectedChannel } }, result => {
          this.isPublish = false;
        });
      }
    }
  }
  /**
   * convert list media of sequence
   * @returns mediaListOfSequence
   */
  async convertMediaListOfSequence(sequence: Media): Promise<Media[]> {
    if (!sequence) {
      return undefined;
    }
    let mediaListOfSequence = undefined;
    const sequenceClone = _.cloneDeep(sequence);
    if (Helper.isSequence(sequence)) {
      let sequenceList = [];
      await this.mediaService
        .getMediasOfSequence(sequenceClone)
        .toPromise()
        .then(sequenceResponse => {
          const sequenceObject = JSON.parse(JSON.stringify(sequenceResponse));
          sequenceClone.getMedia().objectFit = Helper.convertStringToObjectFitEnum(sequenceObject.objectFit);
          sequenceObject.medias.forEach((mediaData, index) => {
            mediaData.media = Helper.convertMediaData(mediaData.media);
            if (!mediaData.media || (Helper.isSound(mediaData.media) && !Helper.isImage(sequenceObject.medias[index - 1].media))) {
              return;
            }
            mediaData.media.validFrom = mediaData?.validFrom;
            mediaData.media.validTo = mediaData?.validTo;
            if (mediaData.media.type == TypeMediaFileEnum.MP4) {
              mediaData.media.in = mediaData.in;
              mediaData.media.out = mediaData.out;
            } else if (mediaData.media.type !== TypeMediaFileEnum.MP3 && mediaData.media.type !== TypeMediaFileEnum.WAV) {
              mediaData.media.duration = mediaData.duration;
            }
            sequenceList.push(mediaData.media);
          });
          mediaListOfSequence = Helper.convertDataMediasData(sequenceList);
        });
    }
    return mediaListOfSequence;
  }
  /**
   * setting program
   */
  async settingProgram() {
    if (this.isPlayVideo) {
      return;
    }
    if (!this.isSettingProgram) {
      this.isSettingProgram = true;
      if (this.selectedProgram) {
        this.mediaListOfSequence = await this.convertMediaListOfSequence(this.selectedProgram.media);
        this.selectedProgram.media['durationSequence'] = this.calculateDuration();
        this.selectedProgram.startTime = this.oldStartTime;
        this.isChangedData = true;
        this.dialogService.showDialog(
          DialogSettingProgramComponent,
          { data: { program: this.selectedProgram, mediaList: this.dailyScheduleSelected.mediaList } },
          result => {
            this.isChangedData = false;
            this.isSettingProgram = false;
            this.isChangeDailySchedule = true;
          }
        );
      }
    }
  }
}

/**
 * entity class for timestamp
 */
export class Timestamp {
  /**
   * time string
   */
  time: string;
  /**
   * timestamp value in minutes
   */
  minute: number;
  /**
   * true if the timestamp is showed and vice versa
   */
  isShowed: boolean;

  constructor(minute: number, isShowed: boolean) {
    this.minute = minute;
    this.isShowed = isShowed;
    let date = new Date(0);
    date.setMinutes(minute);
    this.time = date.toISOString().substr(11, 5);
  }
}
