import { Constant, DeviceStatusEnum, DeviceTypeEnum } from 'app/config/constants';
import { DateTime } from 'luxon';
import { CustomTagElement } from './custom-tag-element';
import { PublishInfo } from './publish-info';

/**
 * class entity for device
 */
export class Device {
  /**
   * device id
   */
  id: number;
  /**
   * registration id
   */
  registrationId: string;
  /**
   * device id
   */
  deviceId: string;
  /**
   * custom tag 0
   */
  customTag0: CustomTagElement;
  /**
   * custom tag 1
   */
  customTag1: CustomTagElement;
  /**
   * custom tag 2
   */
  customTag2: CustomTagElement;
  /**
   * bus id
   */
  busId: string;
  /**
   * date registration
   */
  dateRegistration: Date;
  /**
   * name model
   */
  modelName: string;
  /**
   * serial no
   */
  serialNo: string;
  /**
   * comment device
   */
  comment: string;
  /**
   * false if device filterd
   */
  isChecked: boolean;
  /**
   * true if delivery is checked, and vice versa
   */
  isCheckedDelivery: boolean;
  /**
   * true if is filtering
   */
  isFilter: boolean;
  /**
   * name of column filter
   */
  lastFilter: string;
  /**
   * digitalSignage
   */
  digitalSignage: PublishInfo;
  /**
   * valid date from dsc publish file
   */
  fromDSC: string;
  /**
   * true if gray
   */
  isOpacityFromDSC: boolean;
  /**
   * list Publish info
   */
  publishInfos: Array<PublishInfo>;
  /**
   * file publish
   */
  publishData: PublishInfo;
  /**
   * valid date from gid publish file
   */
  fromPublishData: string;
  /**
   * true if gray
   */
  isOpacityFromPublishData: boolean;
  /**
   * true if selected device
   */
  isSelected: boolean;
  /**
   *
   */
  displayIdDate: string;
  /**
   * On if device on emergency mode
   */
  isOnEmergency: boolean;
  /**
   * device status
   */
  status: string;
  /**
   * device elapsed time
   */
  elapsedTime: string;
  /**
   * device delivery deadline
   */
  deliveryDeadline: string;
  /**
   * number of status updates
   */
  numberOfStatusUpdates: number;
  /**
   * device progress
   */
  progress: string;
  /**
   * update status date
   */
  updateStatusDate: string;
  /**
   * type of device
   */
  type: DeviceTypeEnum;
  /**
   * screen size of device
   */
  screenSize: string;
  /**
   * true if the device's setting mode is manually
   */
  isManuallySetOperatingSystem: boolean = false;
  /**
   * true if set auto shutdown
   */
  isAutoShutdown: boolean;
  /**
   * screen orientation
   */
  screenOrientation: string;
  /**
   * shutdown time
   */
  timeAutoShutDown: any;
  /**
   * time date line
   */
  timeDateLine: number;

  /**
   * capture url
   */
  captureUrl: string;

  constructor(registrationId?: string, deviceId?: string, busId?: string, screenOrientation?: string) {
    this.registrationId = registrationId;
    this.deviceId = deviceId;
    this.busId = busId;
    this.isOnEmergency = false;
    this.isChecked = true;
    this.isFilter = false;
    this.isSelected = false;
    this.numberOfStatusUpdates = 0;
    this.isAutoShutdown = false;
    this.screenOrientation = screenOrientation;
  }

  /**
   * get method
   * check over deadline
   */
  get isOverDeadline() {
    if (this.status == DeviceStatusEnum.COMPLETED || this.status == DeviceStatusEnum.FAILED || this.status == DeviceStatusEnum.CANCELLED) {
      return false;
    }
    const deliveryDeadline = DateTime.fromFormat(this.deliveryDeadline, 'LLL. dd, yyyy');
    const currentDate = DateTime.local();
    return currentDate.startOf('day') > deliveryDeadline.startOf('day');
  }

  /**
   * get method
   * check coming deadline
   */
  get isUpcomingDeadline() {
    if (
      this.isOverDeadline ||
      this.status == DeviceStatusEnum.COMPLETED ||
      this.status == DeviceStatusEnum.FAILED ||
      this.status == DeviceStatusEnum.CANCELLED
    ) {
      return false;
    }
    const dateBeforeDeadline = DateTime.fromFormat(this.deliveryDeadline, 'LLL. dd, yyyy').plus({ days: -Constant.DAYS_LEFT_TO_DEADLINE });
    const currentDate = DateTime.local();
    return currentDate.startOf('day') >= dateBeforeDeadline.startOf('day');
  }

  /**
   * get method
   * format progress display
   */
  get formatProgress() {
    if (!this.status || this.progress == null || this.progress.length == 0) {
      return '';
    }
    const progressSplit = this.progress.split(' / ');
    const downloaded = +progressSplit[0];
    const total = +progressSplit[1];
    const formatPercent = new Intl.NumberFormat('en-US', {
      style: 'percent'
    });
    return `${downloaded} files/${total} files, ${formatPercent.format(downloaded / total)}`;
  }
}
