import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Helper } from 'app/common/helper';
import { DataService } from 'app/service/data.service';
import { Color } from '../entity/color';

@Component({
  selector: 'app-opacity-slider',
  templateUrl: './opacity-slider.component.html',
  styleUrls: ['./opacity-slider.component.scss']
})
export class OpacitySliderComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;

  @Input()
  color: Color;

  @Input()
  isFontColor: boolean;

  @Input()
  screen: string;

  @Output()
  alpha: EventEmitter<string> = new EventEmitter();

  private ctx: CanvasRenderingContext2D;
  private mousedown: boolean = false;
  private selectedHeight: number;

  constructor(public dataService: DataService) {}

  ngAfterViewInit() {
    this.selectedHeight = (1 - +this.color.a) * this.canvas.nativeElement.height;
    this.draw();
  }

  draw() {
    if (this.canvas) {
      if (!this.ctx) {
        this.ctx = this.canvas.nativeElement.getContext('2d');
      }
      const width = this.canvas.nativeElement.width;
      const height = this.canvas.nativeElement.height;

      this.ctx.clearRect(0, 0, width, height);

      const pattern = this.ctx.createPattern(this.createCaroPattern(), 'repeat');

      const gradient = this.ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, this.color.toRgbHex(this.color) || 'rgba(255, 255, 255, 1)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

      this.ctx.beginPath();
      this.ctx.rect(0, 0, width, height);

      this.ctx.fillStyle = pattern;
      this.ctx.fill();

      this.ctx.fillStyle = gradient;
      this.ctx.fill();
      this.ctx.closePath();

      this.ctx.strokeStyle = 'gray';
      this.ctx.strokeRect(0, 0, width, height);

      if (this.selectedHeight) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = 'white';
        this.ctx.lineWidth = 2;
        this.ctx.rect(0, this.selectedHeight - 2, width, 2);
        this.ctx.stroke();
        this.ctx.closePath();
      }
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedHeight = evt.offsetY;
    this.draw();
    this.emitOpacity();
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedHeight = evt.offsetY;
      this.draw();
      this.emitOpacity();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['color']) {
      if (this.canvas) {
        this.selectedHeight = (1 - +this.color.a) * this.canvas.nativeElement.height;
        this.draw();
      }
    }
  }

  emitOpacity() {
    const opacity = this.getOpacityAtPosition();
    this.alpha.emit(opacity);
    const color = new Color(this.color.r, this.color.g, this.color.b, opacity);
    Helper.updateColorForArea(color, this.dataService, this.isFontColor);
  }

  getOpacityAtPosition() {
    return (1 - this.selectedHeight / this.canvas.nativeElement.height).toFixed(2);
  }

  createCaroPattern(): HTMLCanvasElement {
    const patternCanvas = document.createElement('canvas');
    const ctx = patternCanvas.getContext('2d');
    patternCanvas.width = 10;
    patternCanvas.height = 10;
    ctx.fillStyle = '#c4c4c4';

    ctx.beginPath();
    ctx.fillRect(0, 0, 5, 5);
    ctx.fillRect(5, 5, 5, 5);
    return patternCanvas;
  }
}
