import { CustomTag } from './custom-tag';

export class ConditionFilterDeviceLog {
  id: Number;
  userId: Number;
  customTag1: CustomTag;
  customTag2: CustomTag;
  customTag3: CustomTag;
  deviceId: string;
  conditions: any;
  customTagElement1Ids: Array<number>;
  customTagElement2Ids: Array<number>;
  customTagElement3Ids: Array<number>;

  constructor(userId?: Number, conditions?: any) {
    this.userId = userId;
    this.conditions = conditions;
  }
}
