import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Helper } from 'app/common/helper';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogDeliveryJobComponent } from 'app/dialog/dialog-delivery-job/dialog-delivery-job.component';
import { DialogDeliveryManagerSettingComponent } from 'app/dialog/dialog-delivery-manager-setting/dialog-delivery-manager-setting.component';
import { DialogDeliveryUploadComponent } from 'app/dialog/dialog-delivery-upload/dialog-delivery-upload.component';
import { DialogEmergencyModeComponent } from 'app/dialog/dialog-emergency-mode/dialog-emergency-mode.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { CustomTag } from 'app/model/entity/custom-tag';
import { DeliveryManagerSetting } from 'app/model/entity/delivery-manager-setting';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { PictureArea } from 'app/model/entity/picture-area';
import { PublishInfo } from 'app/model/entity/publish-info';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { SaveDeliveryManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CustomTagService } from 'app/service/custom-tag.service';
import { DeliveryManagerSettingService } from 'app/service/delivery-manager-setting.service';
import { DevicePublishInfoService } from 'app/service/device-publish-info.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawService } from 'app/service/draw.service';
import { EmergencyDataService } from 'app/service/emergency-data.service';
import { MediaService } from 'app/service/media.service';
import { TemplateService } from 'app/service/template.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uniqueId } from 'uuid';
import {
  Constant,
  DeviceStatusEnum,
  DeviceTypeEnum,
  FIELD_COMPONENT,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  PreviewControlEnum,
  PUBLISH_TYPE,
  TemplateTypeEnum,
  TimingOnEnum
} from '../../config/constants';
import { Device } from '../../model/entity/device';
import { DeviceService } from '../../service/device.service';
import { MenuActionService } from '../../service/menu-action.service';

@Component({
  selector: 'app-delivery-manager',
  templateUrl: './delivery-manager.component.html',
  styleUrls: ['./delivery-manager.component.scss']
})
export class DeliveryManagerComponent implements OnInit, OnDestroy {
  DeviceStatusEnum = DeviceStatusEnum;
  /**
   * constant
   */
  PreviewControlEnum = PreviewControlEnum;
  /**
   * list all device
   */
  deviceList: Array<Device> = new Array<Device>();
  /**
   * delivery selected
   */
  deliverySelected: Device;
  /**
   * all columns
   */
  columns: any[] = [];
  /**
   * list custom tag
   */
  listCustomTag = [];
  /**
   * subscription list
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * list devices on display
   */
  devicesDisplay: Array<Device> = new Array<Device>();
  /**
   * name of column show popup
   */
  propertyShowUp: string = Constant.EMPTY;
  /**
   * name of last column filter
   */
  lastFiltered: string = Constant.EMPTY;
  /**
   * true if show button show popup sort filter
   */
  isSortAndFilter: boolean = false;
  /**
   * list of list options filtered
   */
  listCurrentFilter: IHash = {};
  /**
   * list properties and sort type sorted send to custom sort popup
   */
  listSorted = [];
  /**
   * true if clear last column filtered
   */
  isClear = false;
  /**
   * list options filter memorize checked
   */
  listFilterDisplayOrigin: Array<listFilter> = new Array<listFilter>();
  /**
   * list options filter
   */
  listFilterDisplay: Array<listFilter> = new Array<listFilter>();
  /**
   * number click outside popup
   */
  numberClickOutSide = 0;
  /**
   * true if show Comment
   */
  hasComment: boolean = true;
  /**
   * true if selected all device
   */
  isCheckedAll: boolean = false;
  /**
   * true if selected all filter
   */
  isCheckAllFilter: boolean = true;
  /**
   * true if is sorting
   */
  isSort: string = '';
  /**
   * true if is filter
   */
  isFilter: boolean = false;
  /**
   * true if show div emergency message
   */
  isShowDivEmergency: boolean = false;

  /**
   * emergency data
   */
  emergencyData: EmergencyData = new EmergencyData('', 0);

  /**
   * list all template main
   */
  templateMains: Array<Template>;

  /**
   * template selected
   */
  templateSelected: Template;

  /**
   * canvasContainer
   */
  @ViewChild('canvasContainer', { static: false })
  canvasContainer: ElementRef;

  /**
   * margin top bottom for area preview
   */
  marginForHeight: number;

  /**
   * margin left right for area preview
   */
  marginForWidth: number;

  /**
   * true if is play
   */
  isPlay: boolean = false;

  /**
   * default time out
   */
  readonly DEFAULT_TIMEOUT = 30;

  /**
   * old emergency data
   */
  oldEmergency: EmergencyData = new EmergencyData('', 0);

  /**
   * true if popup display left
   */
  isCheckPopup: boolean = false;

  @ViewChild('table') private table: ElementRef;

  /**
   * fixed-header column
   */
  fixedColumns: any[] = [
    { headerName: 'Device ID', isChecked: false, property: 'deviceId', isSortBy: '', isFilterBy: '' },
    { headerName: 'Mode', isChecked: false, property: 'emergencyMode', isSortBy: '', isFilterBy: '' },
    { headerName: 'Type', isChecked: false, property: 'type', isSortBy: '', isFilterBy: '' },
    { headerName: 'Bus ID', isChecked: false, property: 'busId', isSortBy: '', isFilterBy: '' },
    { headerName: 'Publish Data', isChecked: false, property: 'publishData', isSortBy: '', isFilterBy: '' },
    { headerName: 'From', isChecked: false, property: 'fromPublishData', isSortBy: '', isFilterBy: '' },
    { headerName: 'Digital Signage Channel', isChecked: false, property: 'digitalSignage', isSortBy: '', isFilterBy: '' },
    { headerName: 'From', isChecked: false, property: 'fromDSC', isSortBy: '', isFilterBy: '' },
    { headerName: 'Status', isChecked: false, property: 'status', isSortBy: '', isFilterBy: '' }
  ];
  /**
   *
   */
  deliveryManagerSetting: DeliveryManagerSetting;
  /**
   *
   */
  devicesInProgress: Device[];
  /**
   *
   */
  intervalUpdateStatusForDevices: any;

  private subject$ = new Subject();

  stateOfComponent: {
    isChangeLayout: boolean;
    deviceList: Device[];
    deliverySelected: Device;
    columns: any[];
    listCustomTag: CustomTag[];
    devicesDisplay: Device[];
    lastFiltered: string;
    isSortAndFilter: boolean;
    listCurrentFilter: IHash;
    listSorted: any[];
    isClear: boolean;
    listFilterDisplayOrigin: listFilter[];
    listFilterDisplay: listFilter[];
    hasComment: boolean;
    isCheckedAll: boolean;
    isSort: string;
    isFilter: boolean;
    deliveryManagerSetting: DeliveryManagerSetting;
    devicesInProgress: Device[];
    isShowDivEmergency: boolean;
    emergencyData: EmergencyData;
    templateMains: Array<Template>;
    templateSelected: Template;
    isPlay: boolean;
  };

  constructor(
    private menuActionService: MenuActionService,
    private deviceService: DeviceService,
    private customTagService: CustomTagService,
    private devicePublishInfoService: DevicePublishInfoService,
    private emergencyDataService: EmergencyDataService,
    private templateService: TemplateService,
    private deliveryManagerSettingService: DeliveryManagerSettingService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private drawService: DrawService,
    private renderer: Renderer2,
    private toast: ToastrService,
    private mediaService: MediaService,
    public readonly store: Store<AppState>
  ) {
    // subscribe for sort and filter action
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.sortAndFilter();
        }
      })
    );
    // subscribe for delivery action
    this.subscriptions.push(
      this.menuActionService.actionDelivery.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.delivery();
        }
      })
    );
    // subscribe for open delivery job action
    this.subscriptions.push(
      this.menuActionService.actionOpenDeliveryJob.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.openDeliveryJob();
        }
      })
    );
    // subscribe for delete archive note action
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.deleteArchiveNote();
        }
      })
    );
    // subscribe for open delivery job action
    // subscribe for emergency action
    this.subscriptions.push(
      this.menuActionService.actionEmergency.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.setModeEmergency();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionOpenSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
          this.openSetting();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.deliveryManagerState?.stateOfComponent.isChangeLayout,
            deviceList: componentState.deliveryManagerState?.stateOfComponent.deviceList,
            deliverySelected: componentState.deliveryManagerState?.stateOfComponent.deliverySelected,
            columns: componentState.deliveryManagerState?.stateOfComponent.columns,
            listCustomTag: componentState.deliveryManagerState?.stateOfComponent.listCustomTag,
            devicesDisplay: componentState.deliveryManagerState?.stateOfComponent.devicesDisplay,
            lastFiltered: componentState.deliveryManagerState?.stateOfComponent.lastFiltered,
            isSortAndFilter: componentState.deliveryManagerState?.stateOfComponent.isSortAndFilter,
            listCurrentFilter: componentState.deliveryManagerState?.stateOfComponent.listCurrentFilter,
            listSorted: componentState.deliveryManagerState?.stateOfComponent.listSorted,
            isClear: componentState.deliveryManagerState?.stateOfComponent.isClear,
            listFilterDisplayOrigin: componentState.deliveryManagerState?.stateOfComponent.listFilterDisplayOrigin,
            listFilterDisplay: componentState.deliveryManagerState?.stateOfComponent.listFilterDisplay,
            hasComment: componentState.deliveryManagerState?.stateOfComponent.hasComment,
            isCheckedAll: componentState.deliveryManagerState?.stateOfComponent.isCheckedAll,
            isSort: componentState.deliveryManagerState?.stateOfComponent.isSort,
            isFilter: componentState.deliveryManagerState?.stateOfComponent.isFilter,
            deliveryManagerSetting: componentState.deliveryManagerState?.stateOfComponent.deliveryManagerSetting,
            devicesInProgress: componentState.deliveryManagerState?.stateOfComponent.devicesInProgress,
            isShowDivEmergency: componentState.deliveryManagerState?.stateOfComponent.isShowDivEmergency,
            emergencyData: componentState.deliveryManagerState?.stateOfComponent.emergencyData,
            templateMains: componentState.deliveryManagerState?.stateOfComponent.templateMains,
            templateSelected: componentState.deliveryManagerState?.stateOfComponent.templateSelected,
            isPlay: componentState.deliveryManagerState?.stateOfComponent.isPlay
          };
        })
    );
  }

  ngOnInit(): void {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.fetchDeviceData();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveDeliveryManagerStateAction({
        isChangeLayout: true,
        deviceList: this.deviceList,
        deliverySelected: this.deliverySelected,
        columns: this.columns,
        listCustomTag: this.listCustomTag,
        devicesDisplay: this.devicesDisplay,
        lastFiltered: this.lastFiltered,
        isSortAndFilter: this.isSortAndFilter,
        listCurrentFilter: this.listCurrentFilter,
        listSorted: this.listSorted,
        isClear: this.isClear,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        listFilterDisplay: this.listFilterDisplay,
        hasComment: this.hasComment,
        isCheckedAll: this.isCheckedAll,
        isSort: this.isSort,
        isFilter: this.isFilter,
        deliveryManagerSetting: this.deliveryManagerSetting,
        devicesInProgress: this.devicesInProgress,
        isShowDivEmergency: this.isShowDivEmergency,
        emergencyData: this.emergencyData,
        templateMains: this.templateMains,
        templateSelected: this.templateSelected,
        isPlay: this.isPlay
      })
    );
  }

  private handleAfterChangeLayout() {
    this.deviceList = this.stateOfComponent.deviceList;
    this.deliverySelected = this.stateOfComponent.deliverySelected;
    this.columns = this.stateOfComponent.columns;
    this.listCustomTag = this.stateOfComponent.listCustomTag;
    this.devicesDisplay = this.stateOfComponent.devicesDisplay;
    this.lastFiltered = this.stateOfComponent.lastFiltered;
    this.isSortAndFilter = this.stateOfComponent.isSortAndFilter;
    this.listCurrentFilter = this.stateOfComponent.listCurrentFilter;
    this.listSorted = this.stateOfComponent.listSorted;
    this.isClear = this.stateOfComponent.isClear;
    this.listFilterDisplayOrigin = this.stateOfComponent.listFilterDisplayOrigin;
    this.listFilterDisplay = this.stateOfComponent.listFilterDisplay;
    this.hasComment = this.stateOfComponent.hasComment;
    this.isCheckedAll = this.stateOfComponent.isCheckedAll;
    this.isSort = this.stateOfComponent.isSort;
    this.isFilter = this.stateOfComponent.isFilter;
    this.deliveryManagerSetting = this.stateOfComponent.deliveryManagerSetting;
    this.devicesInProgress = this.stateOfComponent.devicesInProgress;
    this.isShowDivEmergency = this.stateOfComponent.isShowDivEmergency;
    this.emergencyData = this.stateOfComponent.emergencyData;
    this.templateMains = this.stateOfComponent.templateMains;
    this.templateSelected = this.stateOfComponent.templateSelected;
    this.isPlay = this.stateOfComponent.isPlay;
    this.handleSelectTemplate(this.templateSelected);
    if (this.devicesInProgress.length == 0 || !this.deliveryManagerSetting.isStatusAutoRefresh) {
      return;
    }
    this.intervalUpdateStatusForDevices = this.updateStatusForDevicesInterval();
  }

  /**
   * fetch device data
   */
  private fetchDeviceData() {
    this.deviceService.getDevice().subscribe(
      async data => {
        this.deviceList = data.map(device => this.convertDataDeviceFromBackend(device));
        this.deviceList.forEach(device => {
          this.setOpacity(device);
        });
        this.updateHeader();
        this.devicesDisplay = [...this.deviceList];
        this.devicesInProgress = _.cloneDeep(
          this.deviceList.filter(
            device =>
              device.status == '' ||
              (device.status &&
                device.status != DeviceStatusEnum.COMPLETED &&
                device.status != DeviceStatusEnum.FAILED &&
                device.status != DeviceStatusEnum.CANCELLED)
          )
        );
        this.deliveryManagerSettingService.getDeliveryManagerSetting().subscribe(
          async settingDataResponse => {
            this.deliveryManagerSetting = settingDataResponse;
            this.handleUpdateStatusForDevices();
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * handle update status for devices
   */
  private async handleUpdateStatusForDevices() {
    if (this.devicesInProgress.length == 0) {
      return;
    }
    await this.updateStatusFirstTimeForDevices().then(() => {
      if (this.devicesInProgress.length == 0 || !this.deliveryManagerSetting.isStatusAutoRefresh) {
        return;
      }
      this.intervalUpdateStatusForDevices = this.updateStatusForDevicesInterval();
    });
  }

  /**
   * update status first time for devices
   */
  private async updateStatusFirstTimeForDevices() {
    return new Promise<void>((resolve, reject) => {
      this.deviceService.updateStatusFirstTimeForDevices(this.devicesInProgress).subscribe(
        devicesData => {
          devicesData.forEach(deviceData => {
            this.updateCurrentStatusDevices(deviceData);
          });
          resolve();
        },
        error => {
          reject();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    });
  }

  /**
   * update status for devices interval
   */
  private updateStatusForDevicesInterval() {
    return setInterval(() => {
      if (this.devicesInProgress.length == 0) {
        this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
        return;
      }
      this.deviceService
        .updateStatusForDevices(this.devicesInProgress)
        .pipe(takeUntil(this.subject$))
        .subscribe(
          devicesData => {
            devicesData.forEach(deviceData => {
              this.updateCurrentStatusDevices(deviceData);
              if (this.devicesInProgress.length == 0) {
                this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
              }
            });
          },
          error => {
            this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
    }, this.deliveryManagerSetting.statusAutoRefreshTime * 1000);
  }

  /**
   * update current status devices
   *
   * @param deviceData
   */
  private updateCurrentStatusDevices(deviceData: Device) {
    let deviceInProgress = this.devicesInProgress.find(device => device.id == deviceData.id);
    if (deviceInProgress) {
      deviceInProgress.updateStatusDate = deviceData.updateStatusDate;
      deviceInProgress.numberOfStatusUpdates = deviceData.numberOfStatusUpdates;
      deviceInProgress.status = deviceData.status;
      deviceInProgress.progress = deviceData.progress;
    }
    let device = this.deviceList.find(device => device.id == deviceData.id);
    if (device) {
      device.updateStatusDate = deviceData.updateStatusDate;
      device.numberOfStatusUpdates = deviceData.numberOfStatusUpdates;
      device.status = deviceData.status;
      device.progress = deviceData.progress;
      device.publishInfos = deviceData.publishInfos;
      this.setOpacity(device);
    }
    let deviceDisplay = this.devicesDisplay.find(device => device.id == deviceData.id);
    if (deviceDisplay) {
      deviceDisplay.updateStatusDate = deviceData.updateStatusDate;
      deviceDisplay.status = deviceData.status;
      deviceDisplay.numberOfStatusUpdates = deviceData.numberOfStatusUpdates;
      deviceDisplay.progress = deviceData.progress;
      device.publishInfos = deviceData.publishInfos;
      this.setOpacity(device);
    }
    if (
      deviceData.status == DeviceStatusEnum.COMPLETED ||
      deviceData.status == DeviceStatusEnum.FAILED ||
      deviceData.status == DeviceStatusEnum.CANCELLED
    ) {
      this.devicesInProgress = this.devicesInProgress.filter(dv => dv.id != deviceData.id);
    }
  }

  /**
   * set opacity for publish file of device
   * @param device
   */
  setOpacity(device: Device) {
    device.publishInfos.forEach(element => {
      if (element.type == PUBLISH_TYPE.DSC) {
        device.digitalSignage = element;
        device.fromDSC = element.effectiveFrom;
        device.isOpacityFromDSC = device.digitalSignage.delivered == Constant.DELIVERED;
      } else {
        device.publishData = element;
        device.fromPublishData = element.effectiveFrom;
        device.isOpacityFromPublishData = device.publishData.delivered == Constant.DELIVERED;
      }
    });
  }

  /**
   * delivery data
   */
  delivery() {
    let listDeviceSelected = this.devicesDisplay.filter(delivery => delivery.isSelected);
    if (listDeviceSelected.length == 0) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: `Please select device.`
          }
        },
        result => {}
      );
      return;
    } else {
      if (
        listDeviceSelected.some(
          device =>
            device.status &&
            (device.status == DeviceStatusEnum.IN_PROGRESS ||
              device.status == DeviceStatusEnum.WAITING ||
              device.status == DeviceStatusEnum.NO_RESPONSE)
        )
      ) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `One of checked devices is in "Waiting" / "In Progress" / "No Response" status.`
          }
        });
        return;
      }
      let isAtLeastOneDeviceNoDataDeliver = false;
      let isAllDeviceNoDataDeliver = true;
      let listDeviceHasData = [...listDeviceSelected];
      listDeviceSelected.forEach(device => {
        if (!device.publishInfos.some(publishFile => publishFile && !publishFile.delivered)) {
          isAtLeastOneDeviceNoDataDeliver = true;
          listDeviceHasData = listDeviceHasData.filter(data => data.id != device.id);
        } else if (isAllDeviceNoDataDeliver) {
          isAllDeviceNoDataDeliver = false;
        }
      });
      if (isAllDeviceNoDataDeliver) {
        // if no data all
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `There is no data to deliver. Please try again.`
          }
        });
        // if some device no data
      } else if (isAtLeastOneDeviceNoDataDeliver && !isAllDeviceNoDataDeliver) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: 'You have selected a device with no new data. Do you want to continue?',
              button1: 'Yes',
              button2: 'No',
              title: 'Confirmation'
            }
          },
          result => {
            if (result) {
              this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
              this.dialogService.showDialog(DialogDeliveryUploadComponent, { data: { listDeviceSelected: listDeviceHasData } }, () => {
                this.fetchDeviceData();
                this.isCheckedAll = false;
              });
            }
          }
        );
        // if all device has data
      } else if (!isAtLeastOneDeviceNoDataDeliver && !isAllDeviceNoDataDeliver) {
        this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
        this.dialogService.showDialog(DialogDeliveryUploadComponent, { data: { listDeviceSelected: listDeviceHasData } }, () => {
          this.fetchDeviceData();
          this.isCheckedAll = false;
        });
      }
    }
  }

  /**
   * update header column with custom tag
   */
  updateHeader() {
    this.customTagService.getCustomTag().subscribe(
      data => {
        this.listCustomTag = data;
        this.columns = JSON.parse(JSON.stringify(this.fixedColumns));
        for (let i = 0; i < this.listCustomTag?.length; i++) {
          this.columns.splice(Constant.INDEX_OF_COLUMN_CUSTOM_TAG + i, 0, {
            headerName: this.listCustomTag[i].name,
            isChecked: false,
            property: `customTag${this.listCustomTag[i].indexCustom}`,
            isSortBy: '',
            isFilterBy: ''
          });
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   *
   * check file dropped is delivered or not
   * @param id id of publish file
   * @param idsDevice ids of device checked
   *
   */
  checkDrop(id: number, idsDevice: string) {
    if (!idsDevice) {
      return;
    }
    this.devicePublishInfoService.dropPublishInfo(id, idsDevice).subscribe(data => {
      data.forEach(element => {
        let indexOfDevice = this.devicesDisplay.findIndex(device => device.id == element.id);
        if (indexOfDevice > -1) {
          this.devicesDisplay[indexOfDevice].publishInfos = element.publishInfos;
          this.setOpacity(this.devicesDisplay[indexOfDevice]);
        }
      });
    });
  }

  /**
   * Drop file
   * @param e event
   * @param value
   */
  dropDelivery(e: any, i: number) {
    let data = e.dataTransfer.getData('data');
    let publishFile = JSON.parse(data);
    let checkedDevices = this.devicesDisplay.filter(device => device.isSelected);
    if (checkedDevices.length == 0) {
      this.dropDataWithoutSelectedDevice(publishFile, i);
    } else {
      this.dropDataWithSelectedDevices(publishFile, checkedDevices);
    }
  }

  /**
   * drop data with selected device
   * @param publishFile file drop
   * @param checkedDevices list device selected
   */
  private dropDataWithSelectedDevices(publishFile: any, checkedDevices: Device[]) {
    let checkedDevicesCanDropData = checkedDevices.filter(
      device =>
        device.status !== DeviceStatusEnum.IN_PROGRESS &&
        device.status !== DeviceStatusEnum.WAITING &&
        device.status !== DeviceStatusEnum.NO_RESPONSE
    );
    if (checkedDevicesCanDropData.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `All device is Waiting/ In progress or No response.`
        }
      });
      return;
    } else if (checkedDevicesCanDropData.length < checkedDevices.length) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Some device is Waiting/ In progress or No response. Do you want to continue?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        results => {
          if (!results) {
            return;
          }
          this.handleDropDataWithSelectedDevices(publishFile, checkedDevicesCanDropData);
        }
      );
    } else {
      this.handleDropDataWithSelectedDevices(publishFile, checkedDevicesCanDropData);
    }
  }

  /**
   * check type file and drop
   * @param publishFile
   * @param checkedDevicesCanDropData list device with status is not waiting, inprogress or no response
   */
  private handleDropDataWithSelectedDevices(publishFile: any, checkedDevicesCanDropData: Device[]) {
    // drop dsc file
    if (publishFile.type === PUBLISH_TYPE.DSC) {
      this.dropPublishFileDSC(publishFile, checkedDevicesCanDropData);
      return;
    }
    // drop other file
    let typeOfPublishFileDropCorrespondsDevice = this.getTypeOfPublishFileDropCorrespondsDevice(publishFile);
    if (checkedDevicesCanDropData.every(device => device.type !== typeOfPublishFileDropCorrespondsDevice)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Type of publish file is incorrect.`
        }
      });
    } else if (checkedDevicesCanDropData.some(device => device.type !== typeOfPublishFileDropCorrespondsDevice)) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Some device has different type. Do you want to continue?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (!result) {
            return;
          }
          let idsDeviceSelect: string = checkedDevicesCanDropData
            .filter(data => data.type === typeOfPublishFileDropCorrespondsDevice)
            .map(device => device.id)
            .join('-');
          this.checkDrop(publishFile.id, idsDeviceSelect);
        }
      );
    } else {
      let idsDeviceSelect: string = checkedDevicesCanDropData.map(device => device.id).join('-');
      this.checkDrop(publishFile.id, idsDeviceSelect);
    }
  }

  /**
   * drop data without selected device
   * @param publishFile
   * @param i
   */
  private dropDataWithoutSelectedDevice(publishFile: any, i: number) {
    let device = this.devicesDisplay[i];
    if (
      device.status == DeviceStatusEnum.IN_PROGRESS ||
      device.status == DeviceStatusEnum.WAITING ||
      device.status == DeviceStatusEnum.NO_RESPONSE
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Device is ${device.status}.`
        }
      });
      return;
    }
    // drop dsc file
    if (publishFile.type === PUBLISH_TYPE.DSC) {
      this.dropPublishFileDSC(publishFile, [], i);
      return;
    }
    // drop other file
    if (device.type !== this.getTypeOfPublishFileDropCorrespondsDevice(publishFile)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Type of publish file is incorrect.`
        }
      });
    } else {
      this.checkDrop(publishFile.id, device.id + '');
    }
  }

  /**
   * check drop file dsc
   * @param publishFileDSC
   * @param devicesChecked
   * @param i index device dropped
   */
  private dropPublishFileDSC(publishFileDSC: PublishInfo, devicesChecked: Device[], i?: number) {
    if (devicesChecked.length < 1) {
      this.checkDrop(publishFileDSC.id as number, this.devicesDisplay[i].id + '');
    } else {
      let idsDeviceSelect: string = devicesChecked.map(device => device.id).join('-');
      this.checkDrop(publishFileDSC.id as number, idsDeviceSelect);
    }
  }

  /**
   * get type of publish file correspond with device type
   * @param publishFile
   * @returns typeOfPublishFileDropCorrespondsDevice;
   */
  private getTypeOfPublishFileDropCorrespondsDevice(publishFile: any): string {
    let typeOfPublishFileDropCorrespondsDevice = '';
    switch (publishFile.type) {
      case PUBLISH_TYPE.BID:
        typeOfPublishFileDropCorrespondsDevice = DeviceTypeEnum.ON_BUS_DISPLAY;
        break;
      case PUBLISH_TYPE.BSD:
        typeOfPublishFileDropCorrespondsDevice = DeviceTypeEnum.STATION_DISPLAY;
        break;
      case PUBLISH_TYPE.GID:
        typeOfPublishFileDropCorrespondsDevice = DeviceTypeEnum.SIGNAGE_DISPLAY;
        break;
      case PUBLISH_TYPE.EDS:
        typeOfPublishFileDropCorrespondsDevice = DeviceTypeEnum.DESTINATION_SIGN;
        break;
      default:
        break;
    }
    return typeOfPublishFileDropCorrespondsDevice;
  }

  /**
   * Allow Drop
   * @param e event
   */
  public allowDrop(e) {
    e.preventDefault();
  }

  /**
   * show up or hidden button show popup sort filter
   */
  sortAndFilter() {
    this.isSortAndFilter = !this.isSortAndFilter;
    this.isSort = '';
    if (!this.isSortAndFilter) {
      this.fetchDeviceData();
      this.listSorted.length = 0;
    }
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  sortProperty(property: string, type: string) {
    this.listSorted = [[property], [type]];
    this.devicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.propertyShowUp = Constant.EMPTY;
    this.numberClickOutSide = 0;
    let columnsSorted = this.columns.filter(data => data.isSortBy != '');
    if (columnsSorted.length !== 0) {
      columnsSorted.forEach(element => (element.isSortBy = ''));
    }
    this.columns.find(data => data.property == property).isSortBy = type;
  }

  /**
   * check check all device
   */
  checkCheckAll() {
    this.isCheckedAll = this.devicesDisplay.every(device => device.isSelected);
  }

  /**
   * check all device
   */
  checkAll() {
    this.isCheckedAll = !this.isCheckedAll;
    if (this.isCheckedAll) {
      this.devicesDisplay.forEach(data => (data.isSelected = true));
    } else {
      this.devicesDisplay.forEach(data => (data.isSelected = false));
    }
  }

  /**
   * check all Filter
   */
  checkAllFilter() {
    this.isCheckAllFilter = !this.isCheckAllFilter;
    this.listFilterDisplayOrigin.forEach(data => (data.isChecked = this.isCheckAllFilter));
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  controlCheckBoxCheckAllFilter() {
    if (this.listFilterDisplayOrigin.some(filter => !filter.isChecked)) {
      this.isCheckAllFilter = false;
      return;
    }
    this.isCheckAllFilter = true;
  }

  /**
   * check device
   * @param device device selected
   */
  changeChecked(device: Device) {
    device.isSelected = !device.isSelected;
    this.checkCheckAll();
  }

  /**
   * show popup sort filter
   * @param property name column
   */
  showUp(property: string, event) {
    if (this.propertyShowUp == property) {
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
      return;
    }
    this.propertyShowUp = property;
    this.fetchFilterData(property);
    this.isCheckPopup = event.clientX + Constant.WIDTH_POPUP > this.table.nativeElement.offsetWidth;
  }

  /**
   * hidden popup when click out side
   */
  hiddenPopup() {
    this.numberClickOutSide++;
    if (this.numberClickOutSide == 2) {
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
    }
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  filterBy = function(array, property) {
    var seen = new Set();
    return array.filter(item =>
      property?.startsWith('customTag') ||
      property?.startsWith('busStopEvent') ||
      property?.startsWith('digitalSignage') ||
      property?.startsWith('station') ||
      property?.startsWith('signageDisplay')
        ? !seen.has(item[property]?.name) && seen.add(item[property]?.name)
        : !seen.has(item[property]) && seen.add(item[property])
    );
  };

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  fetchFilterData(property: string) {
    // if not last column filter
    if (this.lastFiltered != property) {
      this.listFilterDisplay = [];
      let listDeviceGetOptionFilter = this.devicesDisplay.filter(data => data.lastFilter == undefined || data.lastFilter == property);
      let listDeviceOptionFilter: Device[] = this.filterBy(listDeviceGetOptionFilter, property);
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: !listDeviceOptionFilter[i].isFilter,
          value:
            property?.startsWith('customTag') ||
            property?.startsWith('busStopEvent') ||
            property?.startsWith('digitalSignage') ||
            property?.startsWith('station') ||
            property?.startsWith('signageDisplay')
              ? listDeviceOptionFilter[i][property]?.name
              : listDeviceOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
    }
    // get list memorize checked
    this.listFilterDisplayOrigin = this.listFilterDisplay.map(x => Object.assign({}, x));
  }

  /**
   * change checked
   * @param index index of option filter
   */
  selectDeviceFilter(index: number) {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * set lastFilter for device to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  getCurrentFilter(currentFilter: listFilter[], property: string) {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.deviceList.filter(data =>
          property?.startsWith('customTag') ||
          property?.startsWith('busStopEvent') ||
          property?.startsWith('digitalSignage') ||
          property?.startsWith('station') ||
          property?.startsWith('signageDisplay')
            ? data[property]?.name == currentFilter[i].value
            : data[property] == currentFilter[i].value
        );
        arr.forEach(element => {
          element.isFilter = true;
          if (element.lastFilter == undefined) {
            element.lastFilter = property;
          }
        });
      } else {
        let arr = this.deviceList.filter(data =>
          property?.startsWith('customTag') ||
          property?.startsWith('busStopEvent') ||
          property?.startsWith('digitalSignage') ||
          property?.startsWith('station') ||
          property?.startsWith('signageDisplay')
            ? data[property]?.name == currentFilter[i].value
            : data[property] == currentFilter[i].value
        );
        arr.forEach(element => {
          if (element.lastFilter == property) {
            element.isFilter = false;
            element.lastFilter = undefined;
          }
        });
      }
    }
  }

  /**
   * filter device
   * @param property
   */
  filterDevice(property: string) {
    this.numberClickOutSide = 0;
    // do not filter all
    if (this.listFilterDisplayOrigin.findIndex(data => data.isChecked) < 0) {
      this.propertyShowUp = Constant.EMPTY;
      this.isCheckAllFilter = true;
      return;
    }
    this.columns.find(data => data.property == property).isFilterBy = property;
    this.isFilter = true;
    this.lastFiltered = property;
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) < 0) {
      delete this.listCurrentFilter[property];
      this.lastFiltered = Object.keys(this.listCurrentFilter)
        .slice(-1)
        .pop();
      this.isClear = false;
      this.isFilter = false;
      this.columns.find(data => data.property == property).isFilterBy = '';
    } else {
      // if filter a column was filtered
      if (
        this.listCurrentFilter[property] &&
        this.lastFiltered != Object.keys(this.listCurrentFilter)[Object.keys(this.listCurrentFilter).length - 1]
      ) {
        let keys = Object.keys(this.listCurrentFilter);
        let nextProperTyFilter = keys[keys.indexOf(property) + 1];
        this.deviceList.forEach(element => {
          if (element.lastFilter == property) {
            element.lastFilter = nextProperTyFilter;
          }
        });
        let listDeviceGetOptionFilter = this.deviceList.filter(
          data => data.lastFilter == nextProperTyFilter || data.lastFilter == undefined
        );
        let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
        for (let i = 0; i < listDeviceOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: listDeviceOptionFilter[i].lastFilter == undefined,
            value:
              nextProperTyFilter?.startsWith('customTag') ||
              nextProperTyFilter?.startsWith('busStopEvent') ||
              nextProperTyFilter?.startsWith('digitalSignage') ||
              nextProperTyFilter?.startsWith('station') ||
              property?.startsWith('signageDisplay')
                ? listDeviceOptionFilter[i][nextProperTyFilter]?.name
                : listDeviceOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    this.deviceList.filter(data => data.lastFilter !== undefined).forEach(data => (data.isSelected = false));
    // get list device show up on screen
    this.devicesDisplay = this.deviceList.filter(data => data.lastFilter == undefined);
    this.devicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.propertyShowUp = Constant.EMPTY;
    this.checkCheckAll();
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  clearFilter(property: string) {
    this.columns.find(data => data.property == property).isFilterBy = '';
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    // if is last column filter
    if (property == this.lastFiltered) {
      this.isClear = true;
      this.filterDevice(property);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.deviceList.forEach(element => {
        if (element.lastFilter == property) {
          element.isFilter = false;
          element.lastFilter = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listDeviceGetOptionFilter = this.deviceList.filter(data => data.lastFilter == nextProperTyFilter || data.lastFilter == undefined);
      let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: listDeviceOptionFilter[i].lastFilter == undefined,
          value:
            nextProperTyFilter?.startsWith('customTag') ||
            nextProperTyFilter?.startsWith('busStopEvent') ||
            nextProperTyFilter?.startsWith('digitalSignage') ||
            nextProperTyFilter?.startsWith('station') ||
            property?.startsWith('signageDisplay')
              ? listDeviceOptionFilter[i][nextProperTyFilter]?.name
              : listDeviceOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let i = 0; i < this.listCurrentFilter[nextProperTyFilter]?.length; i++) {
          if (element.value == this.listCurrentFilter[nextProperTyFilter][i].value) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][i].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
      delete this.listCurrentFilter[property];
      this.checkCheckAll();
    }
  }

  /**
   * show custom sort dialog and sort
   */
  showCustomSort() {
    this.propertyShowUp = Constant.EMPTY;
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, this.columns] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.columns.length; i++) {
          for (let j = 0; j < this.listSorted[0].length; j++) {
            if (this.columns[i]?.property == this.listSorted[0][j]) {
              this.columns[i].isSortBy = this.listSorted[1][j];
            }
          }
        }
        // sort
        this.devicesDisplay.sort(this.dynamicSortMultiple(result));
      }
    });
    this.numberClickOutSide = 0;
  }

  /**
   * sort multiple
   * @param result list properties and sort type sorted
   */
  dynamicSortMultiple(result: any) {
    return function(obj1, obj2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < result[0]?.length) {
        let a = obj1[result[0][i]] ? obj1[result[0][i]] : '';
        let b = obj2[result[0][i]] ? obj2[result[0][i]] : '';
        if (result[1][i] === 'DESC') {
          if (
            result[0][i]?.startsWith('customTag') ||
            result[0][i]?.startsWith('busStopEvent') ||
            result[0][i]?.startsWith('digitalSignage') ||
            result[0][i]?.startsWith('station') ||
            result[0][i]?.startsWith('signageDisplay')
          ) {
            a = obj1[result[0][i]] == null ? '' : obj1[result[0][i]].name;
            b = obj2[result[0][i]] == null ? '' : obj2[result[0][i]].name;
          }
          output = a > b ? -1 : a < b ? 1 : 0;
        } else {
          if (
            result[0][i]?.startsWith('customTag') ||
            result[0][i]?.startsWith('busStopEvent') ||
            result[0][i]?.startsWith('digitalSignage') ||
            result[0][i]?.startsWith('station') ||
            result[0][i]?.startsWith('signageDisplay')
          ) {
            a = obj1[result[0][i]] == null ? '' : obj1[result[0][i]].name;
            b = obj2[result[0][i]] == null ? '' : obj2[result[0][i]].name;
          }
          output = a > b ? 1 : a < b ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * delete all archive note
   */
  deleteArchiveNote() {
    this.devicePublishInfoService.deleteArchiveNote().subscribe(data => {
      this.toast.success('Delete archive successfully.', '');
    });
  }

  /**
   * set mode emergency for device
   */
  private setModeEmergency() {
    if (this.isShowDivEmergency) {
      return;
    }
    const devicesSelected = this.devicesDisplay.filter(device => device.isSelected);
    if (!devicesSelected.length) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to select all devices?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.devicesDisplay.forEach(device => {
            device.isSelected = true;
          });
          this.isCheckedAll = true;
          this.showPopUpModeEmergency();
        }
      );
    } else if (!devicesSelected.some(device => device.publishInfos.length != 0)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `All device have no data delivery.`
        }
      });
    } else {
      this.showPopUpModeEmergency();
    }
  }

  /**
   * show pop up emergency mode
   */
  private showPopUpModeEmergency() {
    this.dialogService.showDialog(DialogEmergencyModeComponent, {}, result => {
      if (result === Constant.EMPTY) {
        return;
      }
      if (result === true) {
        this.isShowDivEmergency = true;
        this.emergencyDataService.getEmergencyData().subscribe(data => {
          if (data.length == 0) {
            return;
          }
          this.emergencyData = data[0];
        });
        this.templateService.getAllTemplateByType(TemplateTypeEnum.MAIN).subscribe(data => {
          this.templateMains = data.map(template => this.convertDataTemplate(template));
          this.templateSelected = this.templateMains[0];
          this.handleSelectTemplate(this.templateSelected);
        });
      } else {
        this.isShowDivEmergency = false;
        this.updateEmergencyModeForDevice(false);
      }
    });
  }

  /**
   * action after select template
   * @param template
   */
  public handleSelectTemplate(template: Template) {
    if (!template) {
      return;
    }
    if (this.isPlay) {
      this.isPlay = false;
      this.drawService.setIsPlay(this.isPlay);
    }
    this.templateService.getDetailedTemplateById(template.id).subscribe(
      templateData => {
        if (!templateData) {
          return;
        }
        this.setUpPreviewEmergency(templateData);
        this.drawEmergency();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * set up canvas and draw
   * @param templateData
   */
  private setUpPreviewEmergency(templateData) {
    this.templateSelected = Helper.convertDataTemplateBackward(templateData);
    this.changeDetectorRef.detectChanges();
    this.setUpAreaEmergency(this.templateSelected);
    // clear node child on preview:
    Helper.clearNodeChild(this.canvasContainer?.nativeElement);
    this.drawService.clearAllThreadDrawTemplate(this.templateSelected);
    Helper.createCanvasTemplate(this.templateSelected, this.canvasContainer, this.renderer);
    Helper.createAllCanvasAreaTemplate(this.templateSelected, this.canvasContainer, this.renderer, true);
    const divDisplay1 = document.getElementById('div-preview');
    this.calculateScaleTransformCanvas(this.canvasContainer, this.templateSelected, divDisplay1);
    this.drawService.drawStartPreview(this.templateSelected, false);
  }

  /**
   * set up to area is on and area emergency data
   * @param template
   */
  private setUpAreaEmergency(template: Template) {
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      if (area.checkTypeTextArea() && area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE) {
        area.index = 1000;
        this.emergencyData.areaText = area as TextArea;
      } else if (area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE) {
        area.index = 1000;
        this.emergencyData.areaPicture = area as PictureArea;
      }
      area.isOn = area.timingOn == TimingOnEnum.FROM_THE_BEGINNING || !area.timingOn;
    });
  }

  /**
   * draw emergency data
   */
  private drawEmergency() {
    if (this.emergencyData.areaText) {
      this.drawService.drawText(this.emergencyData.areaText, this.emergencyData.emergencyText);
    }
    if (this.emergencyData.areaPicture) {
      this.emergencyData.areaPicture.media = this.emergencyData.media;
      this.drawService.drawAreaPicture(this.emergencyData.areaPicture);
    }
  }

  /**
   * action when click button control preview
   * @param control
   */
  public changeControl(control: number) {
    if (!this.templateMains || this.templateMains.length == 0) {
      return;
    }
    let indexCurrentTemplate = this.templateMains.findIndex(data => data.id == this.templateSelected.id);
    switch (control) {
      case PreviewControlEnum.RE_PREVIEW:
        if (indexCurrentTemplate == 0) {
          return;
        }
        this.templateSelected = this.templateMains[0];
        break;
      case PreviewControlEnum.PREV:
        if (indexCurrentTemplate == 0) {
          return;
        }
        this.templateSelected = this.templateMains[indexCurrentTemplate - 1];
        break;
      case PreviewControlEnum.PLAY:
        if (
          this.emergencyData.urlImage !== this.oldEmergency.urlImage ||
          this.emergencyData.emergencyText !== this.oldEmergency.emergencyText
        ) {
          this.drawEmergency();
        }
        this.isPlay = !this.isPlay;
        this.drawService.setIsPlay(this.isPlay);
        return;
      case PreviewControlEnum.PAUSE:
        this.oldEmergency = _.cloneDeep(this.emergencyData);
        this.isPlay = !this.isPlay;
        this.drawService.setIsPlay(this.isPlay);
        return;
      case PreviewControlEnum.NEXT:
        if (indexCurrentTemplate == this.templateMains.length - 1) {
          return;
        }
        this.templateSelected = this.templateMains[indexCurrentTemplate + 1];
        break;
      default:
        break;
    }
    this.handleSelectTemplate(this.templateSelected);
  }

  /**
   * calculate scale transform canvas
   * @param canvasDisplayNode
   * @param display Template object
   * @param divDisplay
   */
  private calculateScaleTransformCanvas(canvasDisplay, display: Template, divDisplay): any {
    if (!divDisplay || !display) {
      return;
    }
    // height div preview without padding 10px
    const divDisplayHeight = 320;
    // width div preview without padding 10px
    const divDisplayWidth = 880;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (display.width > divDisplayWidth) {
      scaleTransform.scaleX = divDisplayWidth / display.width;
    }
    if (display.height > divDisplayHeight) {
      scaleTransform.scaleY = divDisplayHeight / display.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.setMarginForPreview(divDisplayWidth, divDisplayHeight, display.width, display.height, scale);
    this.renderer.setStyle(canvasDisplay.nativeElement, 'transform', 'scale(' + scale + ')');
    this.renderer.setStyle(canvasDisplay.nativeElement, 'margin', `${this.marginForHeight}px ${this.marginForWidth}px `);
    return scale;
  }

  /**
   * set margin for area preview
   */
  private setMarginForPreview(widthMock: number, heightMock: number, widthCanvas: number, heightCanvas: number, scale: number) {
    // set default
    this.marginForHeight = 10;
    // set default
    this.marginForWidth = 10;
    if (widthMock / heightMock < widthCanvas / heightCanvas) {
      this.marginForHeight += (heightMock - heightCanvas * scale) / 2;
    } else {
      this.marginForWidth += (widthMock - widthCanvas * scale) / 2;
    }
  }

  /**
   * action when click delivery emergency
   */
  public deliveryEmergency() {
    const devicesSelected = this.devicesDisplay.filter(device => device.isSelected);
    if (!devicesSelected.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select device.`
        }
      });
    } else if (!devicesSelected.some(device => device.publishInfos.length != 0)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `All device have no data delivery.`
        }
      });
    } else if (!this.emergencyData.emergencyImageId && this.emergencyData.emergencyText?.trim() == Constant.EMPTY) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `No data emergency message.`
        }
      });
    } else {
      this.updateEmergencyModeForDevice(true);
      this.saveEmergency();
    }
  }

  /**
   * update emergency mode for device and call api
   * @param isOnEmergency
   */
  private updateEmergencyModeForDevice(isOnEmergency: boolean) {
    let devicesSelected = this.devicesDisplay.filter(device => device.isSelected && device.publishInfos.length != 0);
    if (!isOnEmergency && devicesSelected.every(device => !device.isOnEmergency)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `All device off emergency mode.`
        }
      });
    } else {
      if (!isOnEmergency) {
        devicesSelected = devicesSelected.filter(device => device.isOnEmergency);
        let registrationIds = this.getListRegistrationId(devicesSelected);
        let data = {
          id: registrationIds,
          timeoutSec: this.DEFAULT_TIMEOUT
        };
        this.deviceService.offEmergencyMode(data).subscribe(data => {
          this.updateEmergencyMode(this.getListRegistrationIdComplete(data, isOnEmergency), isOnEmergency);
        });
      } else {
        this.mediaService.getMediaUrlToDelivery(this.emergencyData.media ? this.emergencyData.media.id : -1).subscribe(dataResponse => {
          let registrationIds = this.getListRegistrationId(devicesSelected);
          let data = {
            id: registrationIds,
            message: this.emergencyData.emergencyText,
            image: dataResponse['url'],
            timeoutSec: this.DEFAULT_TIMEOUT,
            req_id: uniqueId()
          };
          this.deviceService.onEmergencyMode(data).subscribe(data => {
            this.updateEmergencyMode(this.getListRegistrationIdComplete(data, isOnEmergency), isOnEmergency);
          });
        });
      }
    }
  }

  /**
   * get list registration id complete
   * @param response
   * @param isOnEmergency
   */
  private getListRegistrationIdComplete(response: any, isOnEmergency): string[] {
    if (!response) {
      return;
    }
    let registrationIdComplete = new Array();
    if (isOnEmergency) {
      response['completed'].forEach(data => {
        registrationIdComplete.push(data[0]);
      });
    } else {
      registrationIdComplete = response['terminated'];
    }
    return registrationIdComplete;
  }

  /**
   * update emergency mode for device
   */
  private updateEmergencyMode(registrationIds: string[], isOnEmergency: boolean) {
    if (!registrationIds || registrationIds.length == 0) {
      return;
    }
    this.deviceService.updateEmergencyMode(registrationIds, isOnEmergency).subscribe(
      deviceId => {
        deviceId.forEach(id => {
          this.devicesDisplay.filter(device => device.id == id)[0].isOnEmergency = isOnEmergency;
        });
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * save emergency data
   */
  private saveEmergency() {
    let emergencyUpdate = new EmergencyData(this.emergencyData.emergencyText, this.emergencyData.emergencyImageId);
    emergencyUpdate.id = this.emergencyData.id;
    this.emergencyDataService.save(emergencyUpdate).subscribe(
      data => {
        this.emergencyData.id = data.id;
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * get list registration id
   * @param devices
   */
  private getListRegistrationId(devices: Device[]): string[] {
    return devices.map(device => device.registrationId);
  }

  /**
   * drop picture to emergency picture
   */
  public dropPicture(event: any) {
    if (this.isPlay) {
      return;
    }
    if (
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(event.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    event.preventDefault();
    var mediaObject = JSON.parse(event.dataTransfer.getData(Constant.MEDIA_VALUE));
    let media = Helper.convertMediaData(mediaObject);
    if (!Helper.isImage(media)) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Only picture is allowed.' } }, result => {});
      return;
    }
    media.name = Helper.convertMediaNameBackWard(media.name, media.mediaNameEncode);
    this.emergencyData.media = media;
    this.emergencyData.urlImage = media.url;
    this.emergencyData.emergencyImageId = media.id;
  }

  /**
   * action when click cancel emergency message
   */
  public closeEmergencyMessage() {
    this.isShowDivEmergency = false;
    this.emergencyData = new EmergencyData('', 0);
    this.setModeEmergency();
  }

  /**
   * open dialog delivery job
   */
  openDeliveryJob() {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.dialogService.showDialog(
      DialogDeliveryJobComponent,
      {
        data: { deliveryManagerSetting: this.deliveryManagerSetting }
      },
      result => {
        if (result) {
          this.isSortAndFilter = true;
          result.forEach(deviceData => {
            this.updateCurrentStatusDevices(deviceData);
          });
          this.fetchFilterData('deviceId');
          this.listFilterDisplayOrigin.forEach(item => {
            if (!result.map(device => device.deviceId).includes(item.value)) {
              item.isChecked = false;
            } else {
              item.isChecked = true;
            }
          });
          this.filterDevice('deviceId');
          this.handleUpdateStatusForDevices();
          return;
        }
        this.fetchDeviceData();
      }
    );
  }

  /**
   * open dialog setting
   */
  openSetting() {
    this.clearIntervalForComponent(this.intervalUpdateStatusForDevices);
    this.dialogService.showDialog(
      DialogDeliveryManagerSettingComponent,
      {
        data: { deliveryManagerSetting: Object.assign({}, this.deliveryManagerSetting) }
      },
      async result => {
        if (result) {
          this.deliveryManagerSettingService.save(result).subscribe(settingDataResponse => {
            this.deliveryManagerSetting = settingDataResponse;
            this.fetchDeviceData();
          });
        } else {
          this.fetchDeviceData();
        }
      }
    );
  }

  /**
   * clear interval
   */
  private clearIntervalForComponent(interval: any) {
    if (interval) {
      clearInterval(interval);
      this.cancelHttpRequest();
    }
  }

  /**
   * convert data device
   *
   * @param deviceDataResponse
   */
  private convertDataDeviceFromBackend(deviceDataResponse: any): Device {
    let device = new Device();
    device.id = deviceDataResponse['id'];
    device.busId = deviceDataResponse['busId'];
    device.type = deviceDataResponse['type'];
    device.registrationId = deviceDataResponse['registrationId'];
    device.deviceId = deviceDataResponse['deviceId'];
    device.comment = deviceDataResponse['comment'];
    device.customTag0 = deviceDataResponse['customTag0'];
    device.customTag1 = deviceDataResponse['customTag1'];
    device.customTag2 = deviceDataResponse['customTag2'];
    device.dateRegistration = deviceDataResponse['dateRegistration'];
    device.deliveryDeadline = deviceDataResponse['deliveryDeadline'];
    device.elapsedTime = deviceDataResponse['elapsedTime'];
    device.modelName = deviceDataResponse['modelName'];
    device.numberOfStatusUpdates = deviceDataResponse['numberOfStatusUpdates'];
    device.progress = deviceDataResponse['progress'];
    device.publishInfos = deviceDataResponse['publishInfos'];
    device.serialNo = deviceDataResponse['serialNo'];
    device.status = deviceDataResponse['status'];
    device.updateStatusDate = deviceDataResponse['updateStatusDate'];
    device.isOnEmergency = deviceDataResponse['isOnEmergency'];
    return device;
  }

  // convert data template
  private convertDataTemplate(templateResponse: any): Template {
    let template = new Template();
    // set id template
    template.id = templateResponse[0];
    // set name template
    template.name = templateResponse[1];
    // set name group
    template.templateGroupName = templateResponse[2];
    return template;
  }

  /**
   * cancel http request
   */
  private cancelHttpRequest() {
    this.subject$.next();
  }
}

/**
 * option filter
 */
export interface listFilter {
  isChecked: boolean;
  value: string;
}

/**
 * list of list options filtered
 */
export interface IHash {
  [details: string]: listFilter[];
}
