/**
 * class entity for device log
 */
export class DeviceLog {
  id: number;
  registrationId: string;
  deviceId: string;
  network: boolean;
  systemTime: string;
  firmwareVersion: string;
  deviceScreenCaptureUrl: string;

  constructor(registrationId?: string, deviceId?: string) {
    this.registrationId = registrationId;
    this.deviceId = deviceId;
  }
}
