import { DataExternalSetting } from './data-external-setting';

export class DisplayTemplate {
  idMainPage: Number;
  idSubPage1: Number;
  idSubPage2: Number;
  idSubPage3: Number;
  idSubPage4: Number;
  idEmergencyPage: Number;
  externalContentMainPage: DataExternalSetting[];
  externalContentPage1: DataExternalSetting[];
  externalContentPage2: DataExternalSetting[];
  externalContentPage3: DataExternalSetting[];
  externalContentPage4: DataExternalSetting[];
  externalContentEmergencyPage: DataExternalSetting[];

  constructor(
    idMainPage?: Number,
    idSubPage1?: Number,
    idSubPage2?: Number,
    idSubPage3?: Number,
    idSubPage4?: Number,
    idEmergencyPage?: Number
  ) {
    this.idMainPage = idMainPage;
    this.idSubPage1 = idSubPage1;
    this.idSubPage2 = idSubPage2;
    this.idSubPage3 = idSubPage3;
    this.idSubPage4 = idSubPage4;
    this.idEmergencyPage = idEmergencyPage;
  }
}
